@import url(https://use.fontawesome.com/releases/v5.13.0/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.13.0/css/all.css);
.userLoginP{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialogP{
  max-width: 470px;
}
.modal-header {
  background-color: #F9F7FA;
  border-radius: 20px 20px 0px 0px;
}
.modal-content {
  border-radius: 20px !important;
}
.modal-title{
  color: #5C5C5C;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}
.modal-body h3 {
  color: #3B3B3B;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.modal-footer {
  display: block;
}
.modal-footer p {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #5C5C5C;
  text-align: center;
}
.modal-footer a {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #803984;
}
.modal-body p {
  text-align: center;
  margin: 20px 0px;
  font-size: 13px;
  color: #555555;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.fade {
  transition: opacity .15s linear;
}
.txtlabel{
  font-size: 13px;
  text-align: left !important;
  color: #555555;
  display: inline-block;
  margin-bottom: .5rem;
  font-family: 'Montserrat', sans-serif;
}
.txtinput {
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #BCBCBC;
  border-radius: 5px;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
}
.donutchart-track{fill:transparent;stroke:#ddd;stroke-width:26}.donutchart-track-inner{fill:#ddd;stroke-width:26}.donutchart-indicator{fill:transparent;stroke:#7f3883;stroke-width:26;stroke-dasharray:0 10000;transition:stroke-dasharray .3s ease}.donutchart{margin:0 auto;border-radius:45%;display:block}.donutchart-text{font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;fill:#7f3883;font-weight:600;padding-top:20px}.donutchart-text-val{font-size:14px}.donutchart-text-percent{font-size:14px}.donutchart-text-label{font-size:9px}

.star {
    color: gray;
    font-size: 25px;
  }
.selDate{
    background-color: #7F3883;
    padding: 5px 7px;
    border-radius: 50px;
    color: #fff;
}
.selRow{
    background-color: #7f388321;
    border-radius: 50px !important;
}


.modal-header{
    background-color: #f9f7fa;
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
}
.modal-content{
     border-radius: 20px;
     opacity: 1,
}
.txtasn{
    padding: 0px 0px 0px 0px;
}
.modal-footer{
    width:90%;
    margin:0 auto;
}
.modal-footer .form-group{
    width:100%;
    margin:0 auto;
}
.modal-footer .form-group label {
   font-size: 13px !Important;
}
.modal-footer .form-group textarea {
   font-size: 13px !Important;
}
body.open-modal{
    overflow: hidden;
}
.inputUploadStyle{display:none}

.inputUploadStyle{display:none}

.inputUploadStyle{display:none}

.inputUploadStyle{display:none}

body{
    font-family: 'Open Sans', sans-serif;
}
h1, h2, h3, h4{
    font-family: 'Montserrat', sans-serif;
}
.font-mont{
    font-family: 'Montserrat', sans-serif;
}
.font-open{
    font-family: 'Open Sans', sans-serif;
}
.font-poppins{
    font-family: 'Poppins', sans-serif;  
}

.dashboard{
    background-color: #39183b;
}

.dashboard .left-sidebar {
    flex: 0 0 300px;
    max-width: 300px;
    background-color: #39183b;
}
.dashboard .left-sidebar .left-sidebar-inner {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}
.dashboard .left-sidebar .left-sidebar-inner .logo {
    padding: 20px 25px;
    background-color: #261427;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
    padding: 25px 25px 20px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
    margin-bottom: 10px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.attndce {
    font-size: 12px;
    margin-left: 16px;
    margin-top: 10px;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #431d46;
    background-color: #431d46;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    text-decoration: none !important;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link:hover,
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link.active{
    background-color: #7f3883;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link img {
    height: 18px;
    width: 100% !important;
    max-width: 20px;
    object-fit: contain;
    margin-right: 12px;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link span:not(.badge) {
    flex: 1 1;
}

.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu .nav-bottom li .menu-link {
    background-color: transparent;
    border-color: #562b59;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu .nav-bottom li.menu-item .menu-link:hover,
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu .nav-bottom li.menu-item .menu-link.active{
    background-color: #7f3883;
}

.dashboard .right-sidebar {
    background-color: #f7f2f7;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 300px);
    max-width: calc(100% - 300px);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    border-radius: 35px 0 0 35px;
    -webkit-border-radius: 35px 0 0 35px;
    -moz-border-radius: 35px 0 0 35px;
    -ms-border-radius: 35px 0 0 35px;
    -o-border-radius: 35px 0 0 35px;
    overflow: hidden;
    position: relative;
}

header .toggle-icon a.btn {
    color: #803a84;
    background-color: #ede6ed;
    border-radius: 40px;
    font-size: 18px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-rightbar .saerchbar {
    flex: 0 0 400px;
    max-width: 400px;
}
.top-rightbar .saerchbar .input-group input.form-control {
    font-size: 14px;
    border-radius: 30px;
    min-height: 42px;
    padding: 5px 50px 5px 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: none;
}
.top-rightbar .saerchbar .input-group .input-group-append {
    position: absolute;
    right: 1px;
    height: 40px;
    top: 1px;
    width: auto;
    z-index: 11;
}
.top-rightbar .saerchbar .input-group button#search {
    background-color: #ede6ed;
    border-radius:30px;
    border: none;
    color: #7f3883;
    font-size: 19px;
    width: 42px;
    padding: 5px 11px;
    box-shadow: none;
    outline: none;
    -webkit-border-radius:30px;
    -moz-border-radius:30px;
    -ms-border-radius:30px;
    -o-border-radius:30px;
}
.top-rightbar .notification button.btn {
    border-radius: 30px !important;
    height: 43px;
    width: 43px;
    background-color: #ede6ed;
    box-shadow: none;
    outline: none;
    border-color: #ede6ed;
    color: #7f3883;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-rightbar .notification button.btn span.badge {
    position: absolute;
    right: -14px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    border: 2px solid #fff;
    top: -4px;
    padding-top: 3px;
}
.userprofile a {
    color: #000;
    text-decoration: none !important;
    align-items: center;
}
.userprofile a .userimg {
    margin-right: 8px;
}
.userprofile a .userimg img {
    height: 46px;
    width: 46px;
    object-fit: cover;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.userprofile a .user-name h5 {
    color: #333;
    font-size: 16px;
    padding-bottom: 0px;
    font-weight: 600;
}
.userprofile a .user-name p {
    font-size: 13px;
    font-weight: 500;
    color: #999;
    text-transform: uppercase;
}
.dash-content{
    padding: 20px 25px;
}
.cus-heading {
    color: #000;
    font-size: 26px;
    font-weight: 600;
}
.cus-heading span{
    font-size: 34px;
}
.card-theme {
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #fff;
    box-shadow: 0 0 13px rgb(127 56 131 / 5%);
}
.card-theme .card-header {
    background-color: #faf7fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 57px;
}
.card-theme .card-header h4 {
    font-size: 18px;
    margin-bottom: 0;
    color: #666;
    font-weight: 600;
    text-shadow: 1px 0px #a5a5a5;
}
.card-theme .card-header .viewall {
    font-weight: 600;
    color: #7f3883;
    font-size: 14px;
    text-decoration: none !important;
}
.card-theme .card-header .viewall .badge {
    font-size: 14px;
    padding: 2px;
    border-radius: 30px;
    height: 32px;
    width: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.card-theme .card-header .viewall .badge-theme {
    background-color: #7f3883;
    color: #fff;
}
.btn-theme-light {
    background-color: #ede6ed !important;
    color: #7f3883 !important;
    border-color: #ede6ed !important;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.btn-theme-light:hover,
.btn-theme-light:focus {
    background-color: #7f3883 !important;
    color: #fff !important;
    border-color: #7f3883 !important;
}
.clbrdr {
    background-color: #EDE6ED !important;
    border: 1px dashed #7F3883 !important;
}
.btn-theme{
    background-color: #7f3883 !important;
    color: #fff !important;
    border-color: #7f3883 !important;
}
.btn-rounded{
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}
.content-slider .course-name h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0;
}
.content-slider .course-name>img {
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.content-slider .course-des p {
    font-size: 14px;
    color: #808080;
}
.content-slider .course-des .author a {
    color: #7f3883;
}
.content-slider .course-des .author {
    font-size: 14px;
    font-weight: 600;
    color: #666;
}
.carousel .carousel-indicators {
    position: unset;
    margin-top: 30px;
    margin-bottom: 0;
}
.carousel .carousel-indicators li {
    background-color: #ccc;
    border-radius: 56px !important;
    height: 6px;
    width: 14px;
    overflow: hidden;
    border: none;
}
.carousel .carousel-indicators li.active {
    background-color: #9d4ea1 !important;
}
.content-slider .course-des .author span.btn {
    background-color: #f7f2f7;
    border-radius: 50px !important;
    height: 35px;
    width: 35px !important;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.content-slider .course-des .author span.btn img {
    height: auto;
    width: 50% !important;
}
.carousel-control-next, .carousel-control-prev {
    height: 38px;
    border: 2px solid #ddd;
    width: 38px;
    border-radius: 50px;
    color: #000 !important;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-control-next span, .carousel-control-prev span{
    color: #999;
}
.sliders .carousel-item button.btn {
    font-size: 14px;
    font-weight: 600;
    transition: all .3s ease;
}
.sliders .carousel-item button.btn:hover {
    background-color: #ede6ed !important;
    border-color: #ede6ed !important;
    color: #7f3883 !IMPORTANT;
}
.btn-theme:hover {
    background-color: #ede6ed !important;
    border-color: #ede6ed !important;
    color: #7f3883 !important;
}
.btn-theme:hover i{
    color: #7f3883 !important;
}
.card-massageinner .cus-message {
    background-color: #f8f8f8;
    border-radius: 15px;
    padding: 10px 8px;
}
.card-massageinner .cus-message .userimg {
    flex: 0 0 40px;
    max-width: 40px;
}
.card-massageinner .cus-message .userimg img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border: 2px solid #ddd;
    border-radius: 50px;
}
.card-massageinner .cus-message .message-detail .message-head {
    justify-content: space-between;
}
.card-massageinner .cus-message .message-detail .message-head h6, .card-massageinner .cus-message .message-detail .message-head .time {
    font-size: 12px;
    margin-bottom: 2px !important;
}
.card-massageinner .cus-message .message-detail .message-head h6 {
    font-weight: 600;
    color: gray;
}
.card-massageinner .cus-message .message-detail .message p {
    font-weight: 600;
    font-size: 13px;
    color: #555;
    margin-bottom: 2px;
}
.card-massageinner .cus-message .message-detail .message div span {
    font-size: 12px;
    color: #999;
}
.card-massageinner .cus-message .message-detail .message-head .time {
    color: #999;
}
.cusbtn p {
    color: gray !important;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}
.cusbtn button.btn {
    font-size: 14px;
    padding: 8px 24px;
    font-weight: 600;
}
.cusbtn button.btn img {
    width: 14px;
    margin-right: 3px;
}
.rate-teacherbox {
    border: 1px solid #ddd;
    border-radius: 15px;
    text-align: center;
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 10px;
}
.rate-teacherbox .teacherimg {
    margin-top: -60px;
    height: 90px;
    width: 90px;
    object-fit: cover;
    border: 5px solid #ddd;
}
.rate-teacherbox h4 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.rate-teacherbox .subject img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border: 2px solid #b2b2b2;
}
.rate-teacherbox .subject p {
    font-weight: 500;
    font-size: 14px;
}
.rate-teacherbox .subject {
    margin-bottom: 11px;
}
.prfle .card-header h4{
    margin:0 auto;
}
.rate-teacherbox button.btn {
    border-radius: 30px !important;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}
.rate-teacher a button.btn.btn-light {
    background-color: #eee !important;
    border-radius: 50px !important;
    font-size: 14px;
    font-weight: 600;
    color: #7f3883;
    padding: 5px 20px;
    margin-top: 6px;
}

.cus-table table thead tr {
    background-color: #ddd;
}
.cus-table table tbody tr .course img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border: 2px solid #b2b2b2;
}
.cus-table table tbody tr .course p {
    font-size: 14px;
    font-weight: 600;
    color: #7f3883;
}
.cus-table table tbody tr td {
    font-size: 14px;
    border: none;
    vertical-align: middle;
}
.cus-table table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}

.cus-table table tbody tr td a {
    color: #212529;
    font-weight: 500;
}
.cus-table table tbody tr td a.btn {
    height: 38px;
    width: 38px;
    padding: 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
}

.cus-table table tbody tr td p.btn {
    font-size: 14px;
    padding: 3px 10px;
    color: #fff;
    font-weight: 600;
}
.cuslessons .card {
    border: 1px solid #ddd;
}
.cuslessons .card .card-header {
    background-color: #fff;
}
.cuslessons .card .card-header h4 {
    color: #000;
    margin-bottom: 5px;
    font-size: 16px;
}
div#filters{
    position: absolute;
    right: 0;
    width: 70%;
    z-index: 1;
    top: 80%; 
}
div#filters {
    display: none; 
}
.cuslessons .card .card-header .course img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border: 2px solid #b2b2b2;
}
.cuslessons .card .card-header .course p {
    font-size: 14px;
    font-weight: 600;
}
.cuslessons .card .card-body .lesson-inner {
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
}
.cuslessons .card .card-body .lesson-inner .lesson-heading {
    color: #999;
}
.cuslessons .card .card-body {
    padding: 12px 10px;
}
.cuslessons .card .card-body .lesson-inner .lesson-value {
    color: #7f3883;
}
.cuslessons .card .card-body .lesson-inner .lesson-value .badge {
    color: #fff;
    background-color: #e5a300 !important;
    border-radius: 20px;
    font-size: 13px;
    font-weight: normal;
    padding: 4px 8px;
}
.badge-warning{
    background-color: #e5a300 !important;
    border-color: #e5a300 !important;
    color: #fff !important;
}
.cus-table table tbody tr td p.btn{
    background-color: #e5a300 !important;
    border-color: #e5a300 !important;
    color: #fff !important;
}
.cuslessons .card .card-foot {
    padding: 15px 12px;
    text-align: center;
}
.cuslessons .card .card-foot button.btn {
    font-size: 13px;
    font-weight: 600;
    padding: 5px 15px;
}
footer {
    text-align: center;
    background-color: #dfcde0;
        margin-top: 8%;
}
footer p{
    margin-bottom:0px;
    color: #7f3883;
    padding: 10px 0px;
}
.nav-tabs .nav-item {
    margin-right: 10px;
    border-radius: 10px 10px 0px 0px;
}
.nav-tabs .nav-item a{
    margin-bottom: -1px;
    background-color: #e5d6e6;
    margin-right: 10px;
    padding: 10px 25px;
    color: #999;
    font-size: 13px;
    border: none !important;
}

.nav-tabs {
    border-bottom: 1px solid #d9c3da;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #7f3883 !important;
    border:none !important;
}
.tab-pane .nmecard {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}
.tab-pane .lesson-heading{
    width:100% !important;
}
.row.icns {
    border: 1px solid #e8e8e8;
    margin: 15px -15px 0px -15px!important;
    padding-bottom: 0px;
}
.tab-pane .card .card-foot{
    border-top: 0px !important;
}
.row.icns i {
    color: #7f3883;
    font-size: 30px;
    text-align: center;
    margin: 0 auto;
    display: block;
}
.row.icns .brdr{
    border-right: 1px solid #e8e8e8;
}
.row.icns .col-md-6{
    padding: 10px !important;
}
.row.icns p {
        margin-bottom: 0px !important;
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
}
.col-md-4.col-5.filtrcol {
    position: inherit;
}
.filters {
    position: absolute;
    right: 1%;
    top: 11.6%;
    padding: 6px 30px 6px 20px;
    font-weight: 600;
    font-size:14px;
    z-index: 111111;
}
.mytaskspge .filters{
    top: 16%;
}
.msgpagetxt .filters{
    top: 13.6%;
}
.coursetxtpge .filters{
    top: 12%;
}
.ordertxtpge .filters{
    top: 15.6%
}
.filters img {
    width: 18px;
    text-align: left;
}
.nav-tabs .badge {
    padding: 6px 8px !important;
}
.mytasks .course img {
    height: 60px !important;
    width: 60px !important;
}
.mytasks .card-body h4{
    font-size: 15px;
    text-align: center;
    font-weight: 700;
}
.mytasks .lesson-heading {
    width: 100px;
    color: #000 !important;
    text-align: center;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
}
.mytasks .card-header{
    padding:20px;
}
.mytasks .icns {
    padding: 20px 0px 10px 0px !important;
}
.mytasks .icns  h4{
    font-size: 18px !important;
}
 .mytasks .prgrs  img{
    width: 120px !important;
    height: auto !important;
    display: block;
    margin: 0 auto !important;
}
.mytasks .icns span{
   font-size: 16px;
    color: #333;
    vertical-align: super;
    padding-left: 5px;
}
.mytasks .icns h3{
        text-align: center;
    color: #7f3883;
    font-size: 28px !important;
    font-weight: 600;
        line-height: 12px;
}
.prgrs .icns{
    padding:0px !important;
}
.prgrs .card{
    min-height: 332px !important;
}
.prgrs .icns p{
    font-size:11px;
    font-family: 'Open Sans', sans-serif;
}
.prgrs .card-foot h3{
    text-align: center;
    color: #7f3883;
    font-size: 28px !important;
    font-weight: 600;
        margin-bottom: 0px;
}
.prgrs .card-foot span{
   font-size: 16px;
    color: #333;
    vertical-align: unset;
    padding-left: 5px;
}
.prgrs .card-foot p{
    font-size:11px;
    font-family: 'Open Sans', sans-serif;
}
.prgrs .card-foot {
    padding:0px !important;
}
.mytasks .prgrs .clck {
    width: 25px !important;
    margin-right: 10px !important;
    display: flex !important;
    position: absolute;
    left: 25%;
    bottom: 10%;
}
.btn.btn-theme-light i {
    margin-right: 10px;
}
p.activedate {
    background-color: #e5a300;
    padding: 5px;
    color: #fff;
    border-radius: 50px;
}
.actvbtn{
        background-color: #7f3883 !important;
    color: #fff !important;
}
.actvbtn img {
    width: 20px;
    margin-right: 5px;
}
.mycrses .row.icns p {
    margin-bottom: 0px !important;
    font-size: 12px !important;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px !important;
}
.h30{
    height:30px;
}
.ratingflex {
    padding: 0px 0px 15px 0px !important;
}

.ratingtb img {
    height: 44px !important;
    width: 45px !important;
    margin-top: 20px;
}
.ratings img {
    width: 80px !important;
    height: auto !important;
    border: none !important;
    border-radius: 0px !important;
}
p.ratings {
    position: absolute;
    left: 32%;
    top: 14%;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.profile-information {
    background-color: #803984;
   padding: 30px 0px 0px 0px;
    border-radius: 20px;
    text-align: center;
}
.profile-img {
    width: 85px;
    margin: 0 auto;
    position:relative;
}
.profile-img img {
    border: 5px solid #fff;
}
a.camera {
    position: absolute;
    bottom: 5px;
    right: 0;
    background-color: #fff;
    padding: 4px 6px;
    border-radius: 50px;
    font-size: 12px;
    color: #803984;
}
.profile-information h3{
    font-size:18px;
    color:#fff;
    text-align:center;
}
.profile-information p{
    font-size:12px;
    color:#fff;
    text-align:center;
}
.profile-information h3 {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
}
.profile-information p {
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding-top: 5px;
    font-family: 'Open Sans', sans-serif;
}
.brdrbtm {
    border-bottom: 1px solid #c09cc2 !important;
}
.prfletext {
    padding: 20px 0px;
}
.prfletext h3 {
    font-size: 24px;
    font-weight: 600;
}
.profile-information .card-header a{
    color:red !important;
}
.table .prfletable {
    border: 0px !important;
    vertical-align: top !important;
}
.prfletable  tbody tr:nth-child(even){
    background-color:#fff !important;
}
.prfletable tbody tr td:last-child {
    text-align: left !important;
}
.prfletable tbody tr td span{
    font-size:12px !important;
}
.prfletable  tbody tr td b{
    font-size:14px !important;
    font-weight: 600;
}
.prfletable tbody tr td {
    padding: 0px .75rem !important;  
    vertical-align: top !important;
}
tr.spcng {
    height: 10px;
}
.prfletabletbody tr td:last-child {
    text-align: left !important;  
}
.prfle .card-header{
    min-height:55px !important;
}
a.camera {
    position: absolute;
    bottom: 5px;
    right: 0;
}
#recent{
    background-color: #eeeeee;
    border-radius: 20px;
    border: 1px solid #cdcdcd;
    margin: 0px;
    margin-top: 20px
}
#recent .card-massageinner .cus-message{
    background-color: #e4e4e4;
    margin-bottom: 20px !important;
}
#recent .card-massageinner {
    padding: 20px;
}
#recent .col-md-5 {
    max-width: 27%;
    flex: 0 0 27%;
}

#recent .col-md-7 {
    max-width: 73%;
    background-color: #fff;
    flex: 0 0 72%;
    border-radius: 20px;
    padding: 20px;
    min-height: 700px;
}
#recent .time i {
    padding-left: 10px;
}

.btm_message_text {
    position: absolute;
    bottom: 0;
    width: 100%;
}
p.smltext {
    text-align: center;
    font-size: 10px;
    color: #a5a5a5;
    padding-bottom: 5px;
    border-bottom: 1px solid #a5a5a5;
    width: 5%;
    margin: 0 auto;
    margin-bottom: 30px;
}

#recent .acvte{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff !important;
}
#recent .cus-heading {
    color: #555555;
    font-size: 20px;
    font-weight: 600;
}
.usrmsg .message{
    background-color: #ebebeb;
    padding: 10px;
    border-radius: 10px;
    width: 50%;
}
.usrmsg .time{
        font-size: 10px;
    color: #a5a5a5;
}
.usrmsg .message p{
        font-size: 12px;
    color: #2e2e2e;
    margin-bottom: 0px;
}
.replymsg .message{
    background-color: #e7dce8;
    padding: 10px;
    border-radius: 10px;
    width: 50%;
    float: right;
}
.replymsg .time{
        font-size: 10px;
    color: #a5a5a5; 
    text-align:right;
    width: 100%;
}
.replymsg .userimg {
    margin-left: .5rem !important;
}
#recent .form-group {
    margin: 30px 40px 30px 0px;
}
#recent .form-group textarea{
    font-size: 12px !important; 
    color: #a5a5a5;
    vertical-align: text-bottom;
    border-radius: 10px;
}
.replymsg .message p{
        font-size: 12px;
    color: #2e2e2e;
    margin-bottom: 0px;
}
.order-left {
    width: 18%;
    float: left;
}
.order-left img{
    height: 210px;
    width:100%;
    object-fit: cover;
    border-radius: 20px 0px 0px 20px;
}
.order-right {
    background-color: #fff;
    border-radius: 0px 20px 20px 0px;
    width: 82%;
    float: left;
    padding: 20px 20px;
}
.order-right p {
    font-size: 12px;
    color: #555555;
     font-family: 'Open Sans', sans-serif;
     margin-bottom:0px !important;
}
.order-right .price {
    font-size: 32px !important;
    color: #803984;
    font-weight: 700;
}
#scheduledorders h3{
    color:#000;
    font-size:20px;
    font-family: 'Open Sans', sans-serif;
}
#scheduledorders .price span {
    color: #555555;
    font-size: 12px;
    font-weight: 400;
}
#scheduledorders .date b {
    color: #803984;
    font-weight: 400;
}
#scheduledorders .date {
    color: #946d97;
    margin-bottom: 20px !important;
}
.oderactve{
    width: 40% !important;
    float: left !important;
    background-color: #7f3883 !important;
    color: #fff !important;
    font-size: 14px;
}
.oderactve i {
    margin-right: 3px  !important;
}
.ordrbtn {
   width: 58%;
    float: left;
    font-size: 14px;
    margin-left: 2%;
}
#scheduledorders h3 i {
    float: right;
    color: #7f3883;
    font-size: 16px;
}
div#scheduledorders {
    padding: 30px 0px;
}
#detail .modal-content{
    background-color: #7f3883 !important;
}
#atndce .modal-content{
    background-color: #7f3883 !important;
}
#atndce h5 {
    color: #fff;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}
#atndce .close {
    color: #fff;
    opacity: 1;
}
#atndce .modal-body p {
    color: #fff;
    font-size: 22px;
    font-family: 'Open Sans', sans-serif; 
    margin-top: -20px;
}
.frmss {
    background-color: #fff;
    padding: 10px 10px;
    width: 150px;
    margin: 0 auto;
}
.frmss .form-check input {
    margin-right: 10px;
}
#detail h5 {
    color: #fff;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}
#detail .close {
    color: #fff;
    opacity: 1;
}
#detail .modal-body p {
    color: #fff;
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    margin-top: -20px;
}
p.asnmnt {
    width: 100%;
    float: left;
    font-size: 15px !important;
    font-weight: 600;
    padding-left: 16%;
    margin-top: -16px; 
}
p.txtasn {
    font-size: 13px !important;
    text-align: justify;
    padding: 50px 0px 0px 0px;
}
#detail .asnmnt {
    padding-left: 18%;
}
.card-foot .fa-arrow-up {
    margin-right: 5px !important;
}
#detail .modal-header{
    width:90%;
    margin:0 auto;
}
#detail .modal-footer{
    width:90%;
    margin:0 auto;
}
#detail .modal-body {
    padding: 20px 30px;
}
.mdlfootleft {
    float: left;
    text-align: left;
    width: 66%;
}
.mdlfootleft p{
    margin-bottom:0px !important;
    font-size:13px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
}
.mdfootright p{
    margin-bottom:0px !important;
    font-size:13px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
}
.sbmitfoot{
    display: block !important;
    margin: 0 auto !important;
    color:#7f3883 !important;
    background-color: #fff !important;
    font-size: 13px !important;
    padding: 6px 22px !important;
    margin-bottom: 30px !important;
    margin-top: 50px !important;
}
.sbmitfoot:hover{
    color:#7f3883 !important;
    background-color: #fff !important;
}
.bgmdl .modal-header{
    background-color: #f9f7fa;
    border-radius: 20px 20px 0px 0px;
}
.bgmdl .modal-content{
     border-radius: 20px;
}
.bgmdl  .txtasn{
    padding: 0px 0px 0px 0px;
}
.bgmdl .modal-footer{
    width:90%;
    margin:0 auto;
}
.bgmdl .modal-footer .form-group{
    width:100%;
    margin:0 auto;
}
.bgmdl .modal-footer .form-group label {
   font-size: 13px !important;
}
.bgmdl .modal-footer .form-group textarea {
   font-size: 13px !important;
}
.prfletable td {
    width: 135px;
}
.sbmitfoot{
    display: block !important;
    margin: 0 auto !important;
    color:#fff !important;
    background-color: #7f3883 !important;
    font-size: 13px !important;
    padding: 6px 22px !important;
    margin-bottom: 30px !important;
    margin-top: 20px !important;
}
.sbmitfoot:hover{
    color:#7f3883 !important;
    background-color: #fff !important;

}
#feedbackmod .modal-body{
    text-align:center;
    margin: 0 auto;
}
#feedbackmod img{
    height:30px;
}
#feedbackmod p{
    font-size:15px;
}
#feedbackmod .usrimg{
    height: 60px;
    border: 2px solid #ced4da;
}
#feedbackmod .ratingimg{
    height: 14px;
    padding-left: 4px;
    border-radius: 0px !important;
    margin-top: -5px;
}
#feedbackmod .modal-footer .form-group label {
    font-size: 13px !important;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}
#filters .modal-title img{
    width: 22px;
    margin-right: 10px;
}
#filters .input-group-addon{
    position: absolute;
    z-index: 11;
    top: 33%;
    left: 10px;
    font-size: 12px;
    color: #bbbbbb;
}
#filters .form-control {
    padding-left: 6%;
    font-size: 12px;
    padding: 15px 20px 15px 6%;
    height: 100%;
    color: #545454;
}
#filters  select {
    padding-left: 10px !important;
}

#schdule img{
    height: 45px;
    width: 45px;
    object-fit: cover;
    border: 2px solid #b2b2b2;
}
.etcp {
    padding-left: 7%;
    margin-top: -2%;
}
.etcp span{
    float: right;
    font-size: 12px;
}
#schdule .modal-dialog{
        max-width: 800px;
}
#schdule h4{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #000;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    width: 95%;
    margin: 0 auto;
    margin-top: 25px;
}
#schdule h4 i {
    padding: 0px 30px;
    color: #7e7e7e;
}
#schdule  .modal-body{
    padding:0px !important;
}
.bgimghd {
    background-color: #eeeeee;
    padding: 15px 16px 1px 16px;
}
.calender-boxes{
    display:flex;
}
#schdule .row{
    padding: 15px 20px;
    border-bottom: 1px solid #dddddd;
    margin: 0px 15px;
}
.calbox1 {
    background-color: #ede6ed;
    margin-right: 20px;
        padding: 9px 12px;
    border-radius: 10px;
}
.calbox1 p {
    font-size: 12px;
    margin-bottom: 0px;
}
.left-cal p {
    color: #7f3884;
}
.left-cal b {
    font-size: 25px;
}
.left-cal{
    padding-right: 15px;
    border-right: 2px solid #fff;
}
.right-cal {
    margin-left: 15px !important;
}
.weektxt{
    position: relative;
    top: 35%;
    font-size: 16px;
    color: #585858;
    font-family: 'Open Sans', sans-serif;
}
.calbox1.d-flex.active {
    background-color: #7f3883;
}
.calbox1.d-flex.active p{
    color:#fff;
}
.cus-message.d-flex.mb-2.replymsg {
    place-content: flex-end;
    margin-right: 30px;
}

button.btn.btn-theme-light.btn-rounded.schedulebtn {
        padding: 10px 25px;
    margin: 0 auto;
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
}
.togleftin {
    flex: 0 0 100px !important;
    max-width: 100px !important;
}
.togleftin li span {
    display: none;
}
.togleftin .menu-link {
    display: flex;
    margin-bottom: 15px;
}
.togleftin .menu-link img{
    height: 30px !important;
}
.logo-mini{
    display:none;
}
.togleftin .logo-mini{
    display:block !important;
}
.togleftin .logo-big{
    display:none !important;
}
.togrightin {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
}
#submitmd .modal-footer span{
    float: left;
    text-align: left;
    width: 100%;
    font-size:13px;
}
#submitmd .modal-footer a{
    background-color: #ede6ed !important;
    color: #7f3883 !important;
    border-color: #ede6ed !important;
    margin-left: 10px;
    padding: 20px;
    font-size: 13px;
    font-weight: 600;
    padding: 7px 10px;
    border-radius: 50px;
}
#submitmd .modal-footer i{
    margin-right:5px !important;
}
.uploadesfiles{
    float: left;
    width: 100%;
}
.uploadesfiles p{
   font-size:13px;
}
.uploadesfiles i{
   font-size:13px;
   color: red;
    padding-left: 10px;
}
textarea#exampleFormControlTextarea1 {
    font-size: 13px;
}
.mobile-header{
    display: none;
}
.msgppdiv{
    display:none;
}
.btn.btn-theme-light i{
        margin-right:0px;
    }
.bimger{
    width: 100%;
    height: 198px;
    background: transparent linear-gradient(180deg, #7F3883 0%, #401C42 100%) 0% 0% no-repeat padding-box;
    box-shadow: -2px 3px 6px #7F388380;
    opacity: 1;
}
h2.allessn {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif !important;
    color: #fff;
    margin-top: 20px;
}
.allessn i {
    background-color: #dfcde0;
    padding: 2px;
    color: #7f3883;
    margin-right: 5px;
    font-size: 16px;
    border-radius: 2px;
}
.bimger img{
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #fff;
}
.bimger .container-fluid {
    width: 85%;
}
.header-bg .container-fluid {
    width: 85%;
}
.lsns h1{
    font-size: 25px;
    color: #fff;
}
.lsns .allessn {
    margin-left: 0px;
    font-weight: 600;
    font-size: 15px;
}
.header-bg {
    margin-top: 3%;
}
.bimger .row.icns{
    border: 0px !important;
    margin: 0px !important
}
.lsns{
    margin-top:3%;
}
.lsns i {
    background-color: transparent;
    color: #fff;
}
span.bgylw {
    background-color: #e5a300;
    padding: 3px 5px;
    border-radius: 50px;
    display: block;
}
.bimger .actvbtn {
    position: absolute;
    right: 0;
    bottom: 26%;
    background-color: #fff !important;
    color: #261427 !important;
    font-weight: 600;
}
.bimger .actvbtn img {
    width: 30px;
    margin-top: 0px;
}
.schdlsn p{
    font-size:13px;
    color:#000;
    line-height: 24px;
}
.schdlsn h2{
    font-weight: 800;
    font-size: 20px; 
    margin-bottom: 10px;
}
.row.schdlsn {
    padding-top: 20px;
}
#accordion .card-header {
    background-color: #fff;
    border-bottom: 0px !important;
        border-radius: 20px !important;
        padding: 5px 1.25rem 5px 7px;
}
#accordion .card{
        border-radius: 20px !important;
            margin-bottom: 10px;
}
#accordion .card-header .btn {
    color: #000;
    font-weight: 700;
}
#accordion .card-body{
    padding: 0px 1.25rem 1.25rem 1.25rem;
}

#accordion .card-header .btn{
        padding: 5px 1.25rem 5px 7px 
}
#accordion .card-header h5:before {
    content: "\f078";
  font-family: 'Font Awesome\ 5 Free'; 
  font-weight: 600; 
  padding-left: 10px;
    font-size: 14px;
}
#accordion .card-header.collapsed h5:before {
    content: "\f077"; 
    font-family: 'Font Awesome\ 5 Free'; 
  font-weight: 600; 
  padding-left: 10px;
    font-size: 14px;
}
.dropdown-item img{
    width:20px;
}
.dropdown-item p{
    font-size: 14px;
}
.dropdown-item {
    padding: 10px 20px 10px 10px  !important;
    margin-right: 15px  !important; 
    border-bottom: 1px solid #ccc;
}
.card-body.p-1.tblbdycrd {
    padding: 0px !important;
}
.card-foot button {
    margin-right: 8px;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.lesnhomepge .card-foot button { 
margin-right: 10px;
    padding-left: 15px !important;
    padding-right: 15px !important;

}
.nav-tabs .nav-link {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.modal-dialog{
    max-width: 400px;
}
.paginav{
    position: absolute;
    bottom: 5%;
    left: 50%;
    z-index: 1111;
}
ul.pagination {
    float: right;
    border-radius: 50px !important;
    background-color: #fff;
    padding:0px;
    margin-right: 3%;
}
.close img{
    width: 20%;
    float: right;
}
#detail .close img{
    -webkit-filter: brightness(0) invert(1)  !important;
            filter: brightness(0) invert(1)  !important;
}
button:focus{
    outline:0px !important;
}
.card-foot button {
    margin-bottom: 20px;
}
.pagination .page-link{
    background-color:transparent;
    border:none;
    font-size: 14px;
    color: #000;
}
.page-link{
    padding:0px !important;
}
li.page-item {
    padding: 8px 10px;
}
a.page-link.actvepgn {
    background-color: #7f3883;
    color: #fff;
    border-radius: 50px;
    padding: 1px 5px !important;
}
.modal-footer .btn i {
    margin-right: 5px;
}
.btn .fa-calendar-alt {
    margin-right: 5px;
}
.btn .fa.fa-eye {
    margin-right: 5px;
}
.cus-table i {
    margin-right: 0px !important;
}
.content-slider .course-name h4 img {
    margin-top: -10px;
}
.close{
        font-size: 16px;
    font-weight: 400;
}
.close i{
    margin-top: 3px;
}
#recent .mbmsgpp .cus-message{
    padding: 10px 20px;
}
.hdmbf .userimg{ 
    flex: 0 0 60px !important;
    max-width: 60px !important;
}
.hdmbf .userimg img{
        height: 60px !important;
    width: 60px !important;
}
.btchss h2 {
    color: black !important;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
}
.btch .ratingtb p {
    font-size: 18px !important;
    margin-bottom: 10px !important;
}
p.ratings.titbtch {
    left: 42%;
    font-size: 20px !important;
    top: 12% !important;
    font-weight: 700 !important;
}
.btch .card-header{
    background-color: #faf7fa !important;
}
.btch .card {
    min-height: 370px;
}
.calenderheader {
    display: flex;
}
.calenderheader {
    background-color: #DDDDDD;
   padding: 0px !important;
    margin-top: 40px;
    border-radius: 25px 25px 0px 0px;
    margin-right: 28px;
}
.divbx {
    display: flex;
}
.daybox {
    width: 145px;
    
}
.frstday{
    width:85px;
}
.daybox span {
    padding-left: 5px;				   
    padding-top: 0px;
    font-size: 12px;
}
.daybox b {
   font-size:20px;
}
.daybox p {
    margin-bottom: 0px;
    padding: 10px 0px;
}
.leftbar {
    background-color: #dddddd;
    border-radius: 0px 0px 0px 20px;
    text-align: center;
}
.calenderbody .col-md-1{
    padding: 0px 0px 0px 15px;
}
.calenderbody .col-md-11 {
    padding: 0px 0px 0px 0px;
}
.clndrbx {
    flex: 1 1;				   
    background-color: #fff; 
    height: 91px;
    text-align: center;
    border: 1px solid #dddd;
    vertical-align: middle;
}
.rightbar {
    border-radius: 20px !important;
    background-color: #fff;
    margin-right: 15px;
}
#calendrpoup .wticnmb {
    margin: 0 auto;
    display: block;
}
.clndrbx.pinkbg {
    background-color: #694F6B;
}
.calenderbody {
    height: 638px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.climg {
    width: 40px;
    padding-left: 10px;
    float: left;
    display: inline-block;
    padding-top: 6px;
}
.leftbar p {
    height: 76.2px;
    align-content: space-between;
    text-align: center;
    padding-top: 25%;
}
.clndrbx p{
    -webkit-margin-before: 0px !important;
            margin-block-start: 0px !important;
    -webkit-margin-after: 0px !important;
            margin-block-end: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}
.clndrdata h3 {
    font-size: 11px;
    color: #fff;
    padding-top: 8px;
}
.clndrdata p {
    font-size: 11px;
    color: #fff;
    padding-left: 40px !important;
}
.cldritem{
    padding-top:4.5%;
}
.cldritem p{
    margin-bottom: 0px !important;
}
.cldritem .row {
    margin-bottom: 20px;
}
.cldritem .card-foot {
    margin: 0 auto;
}
.cldritem .card-foot i {
    margin-right: 5px;
}
table.table-condensed {
    text-align: center;
}
.table-condensed td, th {
    padding: 10px 0px !important;
}
tr.bgtb {
    background-color: #7f388321;
    border-radius: 50px !important;
}
td.btn-primary {
    background-color: #7F3883;
    padding-bottom: 0px !important;
    color: #fff;
    border-radius: 50px;
}
.table-condensed strong{
    background-color: #7F3883;
    padding: 5px 7px;
    border-radius: 50px;
    color: #fff;
}
.table-condensed a.btn.active {
    font-weight: 700;
}
.clndrbx:last-child {
    border-radius: 0px 0px 20px 0px;
}
button.btn.btn-theme-light.btn-rounded.filters.cldrbtn {
    z-index: 1111;
}
.rightbatch{
    display:none;
}
.rightbatch {
    position: absolute;
    right: 0;
    z-index: 11111;
    background-color: #7F3883;
    height: 100%;
}
.rightbatch h2 {
    font-size: 15px;
    color: #fff;
}
.rightbatch p {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}
.rightbatch img {
    width: 100%;
    color: #fff;
}
.rightbatch a {
    -webkit-filter: brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
}
.rightbatchheader {
    background: #39183B;
    padding: 20px;
}
.rightbatchheader1 {
    background: #39183B;
    padding: 20px 20px 0px 20px;
    border-top: 1px solid #fff;
}
.rightbatchheader1 label {
    color: #fff;
    padding-left: 5px;
}
.rightbatchheader1 .col-md-6:last-child{
    text-align:right;
}
.rightbatchbody {
    padding: 40px;
}
.rightbatchbody label {
    padding-left: 19px !important;
}
.rightbatchbody span {
    background-color: #a674a8;
    padding: 10px 10px;
    border-radius: 50px;
    margin-right: 10px;
}
.rightbatchbody label {
    margin-bottom: 15px;
}
button.btn.btn-theme-light.btn-rounded.btnrsnd {
    margin: 0 auto;
    display: block;
    background-color: #fff !important;
    opacity: 1;
    border: none;
}
hr.whitebrdr {
    border-top: 1px solid #ffffff38;
    margin: 2rem 0px;
}
.clndricn i {
    display: inline-block !important;
}
.clndricn .col-md-12 {
    border-bottom: 1px solid #ccc;
    padding: 20px;
}
.clndricn p {
   text-align: left !important;
    padding-left: 20px;
}
.clndrcard .card-header {
    background-color: #EEEEEE !important;
}
.row.icns.clndricn {
    border: none !important;
    margin-top: 0px !important;
}
.clndrcard .card-body{
    padding: 0px !important;
}
span.activedate {
    background-color: #e5a300;
    padding: 5px;
    color: #fff;
    border-radius: 50px;
}
.row.weekrow {
    padding: 20px 10px;
}
span.btn-group.weekbtn {
    text-align: center;
    margin: 0 auto;
    display: block;
    padding-top: 20px;
}
.weekbtn .btn {
    font-weight: 700;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
#schedulepopup .modal-dialog {
    max-width: 720px !important;
}
label.switch {
    text-align: right;
    float: right;
}
.stchmd span{
    text-align: right;
    float: right;
}
.modal  {
    z-index: 1111111;
}
.rightbatch.btchrgt {
    width: 47%;
}
.weekrow p {
    text-align: center;
    color: #585858;
    font-size: 13px;
}
.weekrow span {
   padding-right:10px;
}
.wekhdng h2{
    font-size: 22px !important;
}
.weekrow hr {
    margin-top: 10px;
}
@media screen and (min-width: 1366px) and (max-width: 1600px){
    .card-foot button{
        margin-right: 0px; 
        padding:5px 15px !important;
    }
    .col-xl-3 {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }
    p.activedate{
        padding:0px;
    }
    .card-foot button {
        margin-bottom: 20px;
    }
    .rate-teacherbox .subject p {
        font-weight: 500;
        font-size: 12px;
    }
    .rate-teacherbox button.btn {
        border-radius: 30px !important;
        font-size: 12px;
    }
    p.ratings {
        left: 29%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1365px){
    .col-lg-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    p.ratings {
        position: absolute;
        left: 36%;
    }
    .card-foot button {
        margin-bottom: 20px;
    }
    .rate-teacherbox .subject p {
        font-weight: 500;
        font-size: 12px;
    }
    .rate-teacherbox button.btn {
        border-radius: 30px !important;
        font-size: 12px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1199px){
    .dashboard .left-sidebar {
        flex: 0 0 220px;
        max-width: 220px;
    }
    .dashboard .right-sidebar {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 220px);
        max-width: calc(100% - 220px);
    }
    .dashboard .left-sidebar .left-sidebar-inner .logo {
        padding: 15px 10px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
        padding: 15px 10px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link {
        padding: 9px 12px;
        font-size: 13px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link img {
        height: 14px;
        max-width: 16px;
        margin-right: 9px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
        margin-bottom: 8px;
    }
    .card-foot .btn {
        margin-bottom: 20px;
    }
    .order-left img{
        height: 230px;
    }
    .order-right{
        width: 75%;
    }
    .order-left {
        width: 25%;
    }
    .oderactve i{
        display:none;
    }
    .card-foot button {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1024px){
    .dashboard .left-sidebar {
        flex: 0 0 100px !important;
        max-width: 100px !important;
    }
    .dashboard .right-sidebar {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
    }
    .left-sidebar.show-sidebar.togleftin{
        flex: 0 0 300px !important;
        max-width: 300px !important;
    }
    .right-sidebar.d-flex.flex-column.togrightin{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
    }
    .togleftin li span {
    display: block !important;
    padding-left: 10px;
}
    .cuslessons .card .card-foot {
        padding: 15px 6px;
    }
    .menu-link span{
        display:none;
    }
    .logo-big {
        display:none !important;
    }
    .logo-mini{
        display:block !important;
    }
    .dashboard .left-sidebar .left-sidebar-inner .logo {
        padding: 15px 10px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
        padding: 15px 10px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link {
        padding: 9px 12px;
        font-size: 13px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link img {
            height: 30px;
    max-width: 20px;
    margin-right: 9px;
    margin: 0 auto;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
        margin-bottom: 25px;
    }
    a.logo-mini.d-md-none img {
        display: block;
    }
    .card-theme {
        margin-bottom: 1em;
    }
    .userprofile a .user-name {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    .top-rightbar .saerchbar {
        flex: 0 0 100px;
        max-width: 100px;
        text-align: right;
    }
    header {
        position: relative;
    }
    .top-rightbar .saerchbar .input-group {
        position: absolute;
        width: 90%;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 1px;
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 0 8px rgba(0,0,0,0.1);
        visibility: hidden;
        z-index:11;
        opacity: 1;
        transition: all .1s ease;
        -webkit-transition: all .1s ease;
        -moz-transition: all .1s ease;
        -ms-transition: all .1s ease;
        -o-transition: all .1s ease;
    }
    .top-rightbar .saerchbar .input-group .input-group-append {
        top: 21px;
        right: 21px;
    }
    .top-rightbar .show-searchbox .input-group{
        visibility: visible;
        opacity: 1;
    }
    .top-rightbar .saerchbar .mobile-search button#search {
        border-radius: 50px;
        background-color: #ede6ed;
        height: 42px;
        width: 42px;
        color: #7f3883;
        border-color: #ede6ed;
        box-shadow: none;
        outline: none;
    }
    .dash-content {
        padding: 20px 15px;
    }
   .filters{
       top:0px !important;
   } 
   p.ratings{
           left: 35%;
    top: 14%;
   }
   .order-right {
        margin-bottom: 30px;
    }
    .card-foot button {
        margin-bottom: 20px;
    }
    .col-md-4.col-5.filtrcol{
        position: relative ;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px){
    .cuslessons .card .card-foot {
        padding: 15px 10px;
    }
    .cuslessons .card .card-foot button.btn {
        font-size: 12px;
        padding: 5px 13px;
    }
    .cuslessons .card .card-body .lesson-inner {
        font-size: 12px;
    }
    .cuslessons .card .card-body .lesson-inner .lesson-heading {
        width: 100%;
    }
    .cuslessons .card .card-header .course p {
        font-size: 11px;
    }
    .cus-table table tbody tr td p.btn {
        font-size: 13px;
        white-space: nowrap;
    }
    .cus-table table tbody tr td .course {
        width: 148px;
    }
    .cus-table table tbody tr td:last-child {
        white-space: nowrap;
    }
    .rate-teacherbox .subject p {
        font-size: 12px;
    }
    .rate-teacherbox button.btn {
        font-size: 13px;
    }
}
@media screen and (max-width: 767px){

    .dashboard .right-sidebar{
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: 0 !important;
    }
    .dashboard .left-sidebar.show-sidebar {
        right: 0;
    }
    .close-btn i::before {
        content: "\f00d";
    }
    .userprofile a .user-name {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    .top-rightbar .saerchbar {
        flex: 0 0 300px;
        max-width: 300px;
        text-align: right;
    }
    .card-theme{
        margin-bottom: .61em !important;
        height: auto !important;
    }
    .dashboard .left-sidebar {
        flex: 0 0 260px;
        max-width: 260px;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link {
        padding: 8px 12px !important;
        font-size: 13px !important;
    }
    .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item .menu-link img {
        height: 14px;
        max-width: 16px;
    }
    .nav-tabs {
        border-bottom: 1px solid #d9c3da;
        display: -webkit-inline-box;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: inherit;
    }
    
}
@media screen and (max-width: 575px){
    .dsktphead{
        display:none !important;
    }
    div#filters{
            width: 360px;
    }
        .mobile-header{
            display: block !important;
        }
    .mobile-header header {
        background-color: #261427 !important;
        padding: 20px 10px !important;
        position: relative;
    }
    .top-rightbar .saerchbar {
        flex: 0 0 100px;
        max-width: 100px;
    }
    .wticnmb i {
        display: none;
    }
    .mobile-header .toggle-icon a.btn{
        font-size: 16px;
    height: 30px;
    width: 30px;
    }
    .mobile-header .top-rightbar .notification button.btn {
        height: 30px;
        width: 30px;
        font-size: 14px;
    }
    .mobile-header .top-rightbar .notification button.btn span.badge{
        top: -12px;
    }
    .mobile-header .userprofile a .userimg img {
        height: auto;
    width: auto;
    }
    .mobile-header img{
        width: 100%;
    margin: 0 auto;
    display: block;
    }
    .mobile-header .input-group {
        background-color: #261427;
        padding: 20px 15px;
    }
    .mobile-header .btn-outline-secondary{
        background-color: #ede6ed;
    color: #7f3883;
    border-radius: 50px !important;
        position: absolute;
    right: 3%;
    width: 40px;
    }
    .mobile-header .form-control{
        width: 100%;
    padding: 17px 20px !important;
    border-radius: 50px !important;
    }
    .lgimg {
        position: absolute;
        left: 0;
        width: 45% !important;
        left: 19%;
        top: 25%;
    }
    .filters {
        top: -1.7% !important;
    }
    #detail .modal-body p {
        color: #fff;
        font-size: 20px;
    }
    ul.nav.nav-tabs {
        margin-top: 20px;
    }
    p.asnmnt {
        padding-left: 16%;
    }
    .tab-content .card-body{
            padding: 20px 0px;
    }
    .calender-boxes {
        display: flex;
        overflow-x: scroll;
    }
    .etcp {
        padding-left: 14%;
    }
    #submitmd .modal-footer a{
        display: inline-block;
    margin-top: 10px;
    }
    .order-left{
        width:25%;
    }
    .order-right{
        width:75%;
    }
    .order-left img {
        height: 209px;
    }
    .cuslessons .card .card-foot button.btn{
        font-size:12px;
    }
    .order-right .btn i {
    display: none;
}
    .filters {
        position: relative;
        float:right;
    }
    #scheduledorders h3 {
        color: #000;
        font-size: 18px;
    }
    .cus-heading {
            font-size: 20px;
    text-align: left;
    }
    .profile-information {
        margin-bottom: 20px;
    }
    .card.card-theme.mb-4.prfle {
        margin-bottom: 20px !important;
    }
    .wticnmb img {
        display: none;
    }
    .wticnmb{
        font-size:12px;
    }
    .sliders .carousel-item .row {
        text-align: center;
    }
    .sliders .carousel-item .row .course-name {
        justify-content: center;
    }
    .cuslessons .card .card-body .lesson-inner .lesson-heading {
        width: 100%;
    }
    .cuslessons .card .card-body .lesson-inner {
        font-size: 15px;
    }
    .cuslessons .card .card-body {
        padding: 15px 20px;
    }
    .cuslessons .card .card-foot button.btn {
        width: auto;
        display: inline-block;
    }
    .cus-table table tbody tr td p.btn {
        white-space: nowrap;
    }
    .cus-table table tbody tr td:last-child {
        white-space: nowrap;
    }
    .cus-table table tbody tr td .course {
        white-space: normal;
        width: 140px;
    }
    .dashboard .right-sidebar {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 00px);
        max-width: calc(100% - 0px) !important;
    }
    .dashboard .left-sidebar {
        flex: 0 0 !important;
        max-width: 0px !important;
    }
    .left-sidebar.show-sidebar.togleftin {
        flex: 0 0 80% !important;
        max-width: 80% !important;
        left: 0;
        z-index: 1;
        position: absolute;
        left: 0;
        width: 80%;
    }
    .togleftin li span {
    display: block !important;
    font-size: 16px;
    padding-left: 10px;
}
   
    .moblst ul {
        list-style: none;
        -webkit-padding-start: 0px;
                padding-inline-start: 0px;
        display: -webkit-inline-box;
        margin-left: 15px;
        overflow-x: scroll;
    }
    .menuimg{
         padding: 10px 15px;
        border: 1px solid #431d46;
        background-color: #431d46;
        border-radius: 50px;
        margin-right: 10px;
    }
    .moblst ul li {
        width: auto;
        padding: 10px 5px;
    }
    .moblst li img{
        height: 30px;
    object-fit: contain;
    }
    .moblst .menu-link span{
        display:block !important;
        color: #fff;
       padding-top: 10px;
    text-align: center;
    }
    .moblst {
        background-color: #39183b;
        padding: 10px 0px;
    }
    #recent .col-md-5 {
        max-width: 100%;
        flex: 0 0 100%;
    }
    #recent .col-md-7{
        max-width: 100%;
        flex: 0 0 100%;
        display:none;
    }
    .btm_message_text{
        position:relative;
    }
    .msgppdiv {
        background-color: #e4e4e4;
        border-radius: 20px;
        margin: 0 20px;
    }
    .msgppdiv .name{
        font-size: 16px;
    font-weight: 600;
    }
    .msgppdiv .message p{
        font-size: 10px;
    }
    .msgppdiv .mdcstmmsg {
        padding: 20px 20px 5px 20px;
    }

    .msgppdiv .btm_message_text {
        background-color: #fff;
        border-radius: 20px;
        padding: 50px 5px 20px 5px;
    }
    .msgppdiv .cus-message.d-flex.mb-2.replymsg {
        float: right;
        margin-right: 0px;
    }
    .msgppdiv .form-group textarea{
        margin: 0 auto;
        display: block;
        width: 100%;
        border-radius: 50px !important;
        margin-left: 5%;
        margin-top: 5% !important;
        padding: 20px 0px 20px 20px; 
    }
    .message-detail.lstdv {
        margin-bottom: 30px;
    }
    .hidedv {
        font-size: 30px;
        margin-right: 20px;
        color: #000;
        font-weight: 500;
    }
    .btn.btn-theme-light i{
        margin-right:0px;
    }
    .bimger{
        height:100% !important;
    }
    .bimger .actvbtn{
        position: relative;
        width: auto;
        display: block;
        margin: 0 auto;
        margin-top: 40px;
    }
    .bimger .actvbtn img{
        display:none;
    }
    .bimger img {
        margin-top: 10px;
        border-radius: 10px;
        border: 1px solid #fff;
        margin: 0 auto;
        display: block;
    }
    h2.allessn {
        text-align: center;
         margin-bottom: 20px;
    }
    .close-btn {
        position: absolute;
        z-index: 1;
        right: 25%;
        top: 20px;
    }
}
body {
  font-family: "Open Sans", sans-serif;
}
h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
}
.font-mont {
  font-family: "Montserrat", sans-serif;
}
.font-open {
  font-family: "Open Sans", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}

.dashboard {
  background-color: #39183b;
}

.dashboard .left-sidebar {
  flex: 0 0 300px;
  max-width: 300px;
  background-color: #39183b;
}
.dashboard .left-sidebar .left-sidebar-inner {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}
.dashboard .left-sidebar .left-sidebar-inner .logo {
  padding: 20px 25px;
  background-color: #261427;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
  padding: 25px 25px 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
  margin-bottom: 10px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  li.menu-item
  .menu-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #431d46;
  background-color: #431d46;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  text-decoration: none !important;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  li.menu-item
  .menu-link:hover,
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  li.menu-item
  .menu-link.active {
  background-color: #7f3883;
}
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  li.menu-item
  .menu-link
  img {
  height: 18px;
  width: 100% !important;
  max-width: 20px;
  object-fit: contain;
  margin-right: 12px;
}
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  li.menu-item
  .menu-link
  span:not(.badge) {
  flex: 1 1;
}

.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  .nav-bottom
  li
  .menu-link {
  background-color: transparent;
  border-color: #562b59;
}
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  .nav-bottom
  li.menu-item
  .menu-link:hover,
.dashboard
  .left-sidebar
  .left-sidebar-inner
  .siderbar-menu
  .nav-bottom
  li.menu-item
  .menu-link.active {
  background-color: #7f3883;
}

.dashboard .right-sidebar {
  background-color: #f7f2f7;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 0px);
  max-width: calc(100% - 300px);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  border-radius: 35px 0 0 35px;
  -webkit-border-radius: 35px 0 0 35px;
  -moz-border-radius: 35px 0 0 35px;
  -ms-border-radius: 35px 0 0 35px;
  -o-border-radius: 35px 0 0 35px;
  overflow: hidden;
  position: relative;
}

header .toggle-icon a.btn {
  color: #803a84;
  background-color: #ede6ed;
  border-radius: 40px;
  font-size: 18px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-rightbar .saerchbar {
  flex: 0 0 400px;
  max-width: 400px;
}
.top-rightbar .saerchbar .input-group input.form-control {
  font-size: 14px;
  border-radius: 30px;
  min-height: 42px;
  padding: 5px 50px 5px 20px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  box-shadow: none;
}
.top-rightbar .saerchbar .input-group .input-group-append {
  position: absolute;
  right: 1px;
  height: 40px;
  top: 1px;
  width: auto;
  z-index: 11;
}
.top-rightbar .saerchbar .input-group button#search {
  background-color: #ede6ed;
  border-radius: 30px;
  border: none;
  color: #7f3883;
  font-size: 19px;
  width: 42px;
  padding: 5px 11px;
  box-shadow: none;
  outline: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.top-rightbar .notification button.btn {
  border-radius: 30px !important;
  height: 43px;
  width: 43px;
  background-color: #ede6ed;
  box-shadow: none;
  outline: none;
  border-color: #ede6ed;
  color: #7f3883;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-rightbar .notification button.btn span.badge {
  position: absolute;
  right: -14px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  border: 2px solid #fff;
  top: -4px;
  padding-top: 3px;
}
a.menu-link {
  text-decoration: none;
}
.userprofile a {
  color: #000;
  text-decoration: none !important;
  align-items: center;
}
.userprofile a .userimg {
  margin-right: 8px;
}
.userprofile a .userimg img {
  height: 46px;
  width: 46px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.userprofile a .user-name h5 {
  color: #333;
  font-size: 16px;
  padding-bottom: 0px;
  font-weight: 600;
}
.userprofile a .user-name p {
  font-size: 13px;
  font-weight: 500;
  color: #999;
  text-transform: uppercase;
}
.dash-content {
  padding: 20px 25px;
}
.cus-heading {
  color: #000;
  font-size: 26px;
  font-weight: 600;
}
.row.schdlsn .col-md-12 {
  padding-right: 0px !important;
}
.cus-heading span {
  font-size: 34px;
}
.card-theme {
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #fff;
  box-shadow: 0 0 13px rgb(127 56 131 / 5%);
}
.card-theme .card-header {
  background-color: #faf7fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 57px;
}
.card-theme .card-header h4 {
  font-size: 18px;
  margin-bottom: 0;
  color: #666;
  font-weight: 600;
  text-shadow: 1px 0px #a5a5a5;
}
.card-theme .card-header .viewall {
  font-weight: 600;
  color: #7f3883;
  font-size: 14px;
  text-decoration: none !important;
}
.card-theme .card-header .viewall .badge {
  font-size: 14px;
  padding: 2px;
  border-radius: 30px;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.card-theme .card-header .viewall .badge-theme {
  background-color: #7f3883;
  color: #fff;
}
.btn-theme-light {
  background-color: #ede6ed !important;
  color: #7f3883 !important;
  border-color: #ede6ed !important;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn-theme-light:hover,
.btn-theme-light:focus {
  background-color: #7f3883 !important;
  color: #fff !important;
  border-color: #7f3883 !important;
}
.btn-theme {
  background-color: #7f3883 !important;
  color: #fff !important;
  border-color: #7f3883 !important;
}
.btn-rounded {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}
.content-slider .course-name h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}
.content-slider .course-name > img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.content-slider .course-des p {
  font-size: 14px;
  color: #808080;
}
.content-slider .course-des .author a {
  color: #7f3883;
}
.content-slider .course-des .author {
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.carousel .carousel-indicators {
  position: unset;
  margin-top: 30px;
  margin-bottom: 0;
}
.carousel .carousel-indicators li {
  background-color: #ccc;
  border-radius: 56px !important;
  height: 6px;
  width: 14px;
  overflow: hidden;
  border: none;
}
.carousel .carousel-indicators li.active {
  background-color: #9d4ea1 !important;
}
.content-slider .course-des .author span.btn {
  background-color: #f7f2f7;
  border-radius: 50px !important;
  height: 35px;
  width: 35px !important;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.content-slider .course-des .author span.btn img {
  height: auto;
  width: 50% !important;
}
.carousel-control-next,
.carousel-control-prev {
  height: 38px;
  border: 2px solid #ddd;
  width: 38px;
  border-radius: 50px;
  color: #000 !important;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-control-next span,
.carousel-control-prev span {
  color: #999;
}
.sliders .carousel-item button.btn {
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
}
.sliders .carousel-item button.btn:hover {
  background-color: #ede6ed !important;
  border-color: #ede6ed !important;
  color: #7f3883 !important;
}
.btn-theme:hover {
  background-color: #ede6ed !important;
  border-color: #ede6ed !important;
  color: #7f3883 !important;
}
.btn-theme:hover i {
  color: #7f3883 !important;
}
.card-massageinner .cus-message {
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 10px 8px;
}
.card-massageinner .cus-message .userimg {
  flex: 0 0 40px;
  max-width: 40px;
}
.card-massageinner .cus-message .userimg img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 50px;
}
.card-massageinner .cus-message .message-detail .message-head {
  justify-content: space-between;
}
.card-massageinner .cus-message .message-detail .message-head h6,
.card-massageinner .cus-message .message-detail .message-head .time {
  font-size: 12px;
  margin-bottom: 2px !important;
}
.card-massageinner .cus-message .message-detail .message-head h6 {
  font-weight: 600;
  color: gray;
}
.card-massageinner .cus-message .message-detail .message p {
  font-weight: 600;
  font-size: 13px;
  color: #555;
  margin-bottom: 2px;
}
.card-massageinner .cus-message .message-detail .message div span {
  font-size: 12px;
  color: #999;
}
.card-massageinner .cus-message .message-detail .message-head .time {
  color: #999;
}
.cusbtn p {
  color: gray !important;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.cusbtn button.btn {
  font-size: 14px;
  padding: 8px 24px;
  font-weight: 600;
}
.cusbtn button.btn img {
  width: 14px;
  margin-right: 3px;
}
.rate-teacherbox {
  border: 1px solid #ddd;
  border-radius: 15px;
  text-align: center;
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 10px;
}
.rate-teacherbox .teacherimg {
  margin-top: -60px;
  height: 90px;
  width: 90px;
  object-fit: cover;
  border: 5px solid #ddd;
}
.rate-teacherbox h4 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.rate-teacherbox .subject img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border: 2px solid #b2b2b2;
}
.rate-teacherbox .subject p {
  font-weight: 500;
  font-size: 14px;
}
.rate-teacherbox .subject {
  margin-bottom: 11px;
}
.prfle .card-header h4 {
  margin: 0 auto;
}
.rate-teacherbox button.btn {
  border-radius: 30px !important;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rate-teacher a button.btn.btn-light {
  background-color: #eee !important;
  border-radius: 50px !important;
  font-size: 14px;
  font-weight: 600;
  color: #7f3883;
  padding: 5px 20px;
  margin-top: 6px;
}

.cus-table table thead tr {
  background-color: #ddd;
}
.cus-table table tbody tr .course img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border: 2px solid #b2b2b2;
}
.cus-table table tbody tr .course p {
  font-size: 14px;
}
.cus-table table tbody tr td {
  font-size: 14px;
  border: none;
  vertical-align: middle;
}
.cus-table table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.cus-table table tbody tr td a {
  color: #7f3883;
  font-weight: 600;
  margin-right: 10px;
}
.cus-table table tbody tr td a.btn {
  height: 38px;
  width: 38px;
  padding: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.cus-table table tbody tr td:last-child {
  text-align: right;
}
.cus-table table tbody tr td p.btn {
  font-size: 14px;
  padding: 3px 10px;
  color: #fff;
  font-weight: 600;
}
.cuslessons .card {
  border: 1px solid #ddd;
}
.cuslessons .card .card-header {
  background-color: #eeeeee;
}
.cuslessons .card .card-header h4 {
  color: #000;
  margin-bottom: 5px;
  font-size: 16px;
}
div#filters {
  position: absolute;
  right: 0;
  width: 70%;
  z-index: 1;
  top: 80%;
}
div#filters {
  display: none;
}
.cuslessons .card .card-header .course img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border: 2px solid #b2b2b2;
}
.cuslessons .card .card-header .course p {
  font-size: 14px;
  font-weight: 600;
}
.cuslessons .card .card-body .lesson-inner {
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
}
.cuslessons .card .card-body .lesson-inner .lesson-heading {
  color: #999;
}
.cuslessons .card .card-body {
  padding: 12px 10px;
}
.cuslessons .card .card-body .lesson-inner .lesson-value {
  color: #7f3883;
}
.cuslessons .card .card-body .lesson-inner .lesson-value .badge {
  color: #fff;
  background-color: #e5a300 !important;
  border-radius: 20px;
  font-size: 13px;
  font-weight: normal;
  padding: 4px 8px;
}
.badge-warning {
  background-color: #e5a300 !important;
  border-color: #e5a300 !important;
  color: #fff !important;
}
.cus-table table tbody tr td p.btn {
  background-color: #e5a300 !important;
  border-color: #e5a300 !important;
  color: #fff !important;
}
.cuslessons .card .card-foot {
  padding: 15px 8px;
  text-align: center;
}
.cuslessons .card .card-foot button.btn {
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
}
footer {
  text-align: center;
  background-color: #dfcde0;
  margin-top: 8%;
}
footer p {
  margin-bottom: 0px;
  color: #7f3883;
  padding: 10px 0px;
}
.nav-tabs .nav-item {
  margin-right: 10px;
  border-radius: 10px 10px 0px 0px;
}
.nav-tabs .nav-item a {
  margin-bottom: -1px;
  background-color: #e5d6e6;
  margin-right: 10px;
  padding: 10px 25px;
  color: #999;
  font-size: 13px;
  border: none !important;
}

.nav-tabs {
  border-bottom: 1px solid #d9c3da;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #7f3883 !important;
  border: none !important;
}
.tab-pane .nmecard {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.tab-pane .lesson-heading {
  width: 100% !important;
}
.row.icns {
  border: 1px solid #e8e8e8;
  margin: 15px -15px 0px -15px !important;
  padding-bottom: 0px;
}
.tab-pane .card .card-foot {
  border-top: 0px !important;
}
.row.icns i {
  color: #7f3883;
  font-size: 30px;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.row.icns .brdr {
  border-right: 1px solid #e8e8e8;
}
.row.icns .col-md-6 {
  padding: 10px !important;
}
.row.icns p {
  margin-bottom: 0px !important;
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
}
.col-md-4.col-5.filtrcol {
  position: inherit;
}
.filters {
  position: absolute;
  right: 1%;
  top: 11.6%;
  padding: 6px 30px 6px 20px;
  font-weight: 600;
  font-size: 14px;
  z-index: 111111;
}

.filters img {
  width: 18px;
  text-align: left;
}
.order-right .date {
  margin-bottom: 20px !important;
}
.nav-tabs .badge {
  padding: 6px 8px !important;
}
.mytasks .course img {
  height: 60px !important;
  width: 60px !important;
}
.mytasks .card-body h4 {
  font-size: 15px;
  text-align: center;
  font-weight: 700;
}
.mytasks .lesson-heading {
  width: 100px;
  color: #000 !important;
  text-align: center;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}
.mytasks .card-header {
  padding: 20px;
}
.mytasks .icns {
  padding: 20px 0px 10px 0px !important;
}
.mytasks .icns h4 {
  font-size: 18px !important;
}
.mytasks .prgrs img {
  width: 120px !important;
  height: auto !important;
  display: block;
  margin: 0 auto !important;
}
.mytasks .icns span {
  font-size: 16px;
  color: #333;
  vertical-align: super;
  padding-left: 5px;
}
.mytasks .icns h3 {
  text-align: center;
  color: #7f3883;
  font-size: 28px !important;
  font-weight: 600;
  line-height: 12px;
}
.prgrs .icns {
  padding: 0px !important;
}
.prgrs .card {
  min-height: 332px !important;
}
.prgrs .icns p {
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
}
.prgrs .card-foot h3 {
  text-align: center;
  color: #7f3883;
  font-size: 28px !important;
  font-weight: 600;
  margin-bottom: 0px;
}
.prgrs .card-foot span {
  font-size: 16px;
  color: #333;
  vertical-align: unset;
  padding-left: 5px;
}
.prgrs .card-foot p {
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
}
.prgrs .card-foot {
  padding: 0px !important;
}
.mytasks .prgrs .clck {
  width: 25px !important;
  margin-right: 10px !important;
  display: flex !important;
  position: absolute;
  left: 25%;
  bottom: 10%;
}
.btn.btn-theme-light i {
  margin-right: 10px;
}
p.activedate {
  background-color: #e5a300;
  padding: 5px;
  color: #fff;
  border-radius: 50px;
}
.actvbtn {
  background-color: #7f3883 !important;
  color: #fff !important;
}
.actvbtn img {
  width: 20px;
  margin-right: 5px;
}
.mycrses .row.icns p {
  margin-bottom: 0px !important;
  font-size: 12px !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px !important;
}
.h30 {
  height: 30px;
}
.ratingflex {
  padding: 0px 0px 15px 0px !important;
}

.ratingtb img {
  height: 44px !important;
  width: 45px !important;
  margin-top: 20px;
}
.ratings img {
  width: 80px !important;
  height: auto !important;
  border: none !important;
  border-radius: 0px !important;
}
p.ratings {
  position: absolute;
  left: 40%;
  top: 14%;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.profile-information {
  background-color: #803984;
  padding: 30px 0px 0px 0px;
  border-radius: 20px;
  text-align: center;
}
.profile-img {
  width: 85px;
  margin: 0 auto;
  position: relative;
}
.profile-img img {
  border: 5px solid #fff;
}
a.camera {
  position: absolute;
  bottom: 5px;
  right: 0;
  background-color: #fff;
  padding: 4px 6px;
  border-radius: 50px;
  font-size: 12px;
  color: #803984;
}
.profile-information h3 {
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.profile-information p {
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.profile-information h3 {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
}
.profile-information p {
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  font-family: "Open Sans", sans-serif;
}
.brdrbtm {
  border-bottom: 1px solid #c09cc2 !important;
}
.prfletext {
  padding: 20px 0px;
}
.prfletext h3 {
  font-size: 24px;
  font-weight: 600;
}
.profile-information .card-header a {
  color: red !important;
}
.table .prfletable {
  border: 0px !important;
  vertical-align: top !important;
}
.prfletable tbody tr:nth-child(even) {
  background-color: #fff !important;
}
.prfletable tbody tr td:last-child {
  text-align: left !important;
}
.prfletable tbody tr td span {
  font-size: 12px !important;
}
.prfletable tbody tr td b {
  font-size: 14px !important;
  font-weight: 600;
}
.prfletable tbody tr td {
  padding: 0px 0.75rem !important;
  vertical-align: top !important;
}
tr.spcng {
  height: 10px;
}
.prfletabletbody tr td:last-child {
  text-align: left !important;
}
.prfle .card-header {
  min-height: 55px !important;
}
a.camera {
  position: absolute;
  bottom: 5px;
  right: 0;
}
#recent {
  background-color: #eeeeee;
  border-radius: 20px;
  border: 1px solid #cdcdcd;
  margin: 0px;
  margin-top: 20px;
}
#recent .card-massageinner .cus-message {
  background-color: #e4e4e4;
  margin-bottom: 20px !important;
}
#recent .card-massageinner {
  padding: 20px;
}
#recent .col-md-5 {
  max-width: 27%;
  flex: 0 0 27%;
  height: 640px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
#recent .col-md-7 {
  max-width: 73%;
  background-color: #fff;
  flex: 0 0 72%;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 640px;
}
.cuslessons .card-theme {
  min-height: 356px;
}
#recent .time i {
  padding-left: 10px;
}

.btm_message_text {
  position: inherit;
  bottom: 0;
  width: 100%;
}
p.smltext {
  text-align: center;
  font-size: 10px;
  color: #a5a5a5;
  padding-bottom: 5px;
  border-bottom: 1px solid #a5a5a5;
  width: 5%;
  margin: 0 auto;
  margin-bottom: 30px;
}

#recent .acvte {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff !important;
}
#recent .cus-heading {
  color: #555555;
  font-size: 20px;
  font-weight: 600;
}
.usrmsg .message {
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}
.usrmsg .time {
  font-size: 10px;
  color: #a5a5a5;
}
.usrmsg .message p {
  font-size: 12px;
  color: #2e2e2e;
  margin-bottom: 0px;
}
.replymsg .message {
  background-color: #e7dce8;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  float: right;
  margin-bottom: 20px;
}
.replymsg .time {
  font-size: 10px;
  color: #a5a5a5;
  text-align: right;
  width: 100%;
}
.replymsg .userimg {
  margin-left: 0.5rem !important;
  display: -webkit-inline-box;
  float: right;
}
#recent .form-group {
  margin: 30px 40px 30px 0px;
}
#recent .form-group textarea {
  font-size: 12px !important;
  color: #a5a5a5;
  vertical-align: text-bottom;
  border-radius: 10px;
}
.replymsg .message p {
  font-size: 12px;
  color: #2e2e2e;
  margin-bottom: 0px;
}
.order-left {
  width: 18%;
  float: left;
  margin-bottom: 15px;
}
.order-left img {
  height: 210px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px 0px 0px 20px;
}
.order-right {
  background-color: #fff;
  border-radius: 0px 20px 20px 0px;
  width: 82%;
  float: left;
  padding: 20px 20px;
  box-shadow: 0px 4px 18px 1px #888888;
}
.order-right p {
  font-size: 12px;
  color: #555555;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px !important;
}
.order-right .price {
  font-size: 32px !important;
  color: #803984;
  font-weight: 700;
}
#scheduledorders h3 {
  color: #000;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
#scheduledorders .price span {
  color: #555555;
  font-size: 12px;
  font-weight: 400;
}
#scheduledorders .date b {
  color: #803984;
  font-weight: 400;
}
#scheduledorders .date {
  color: #946d97;
  margin-bottom: 20px !important;
}
.oderactve {
  width: 40% !important;
  float: left !important;
  background-color: #7f3883 !important;
  color: #fff !important;
  font-size: 14px;
}
.oderactve i {
  margin-right: 3px !important;
}
.ordrbtn {
  width: 58%;
  float: left;
  font-size: 14px;
  margin-left: 2%;
}
#scheduledorders h3 i {
  float: right;
  color: #7f3883;
  font-size: 16px;
}
div#scheduledorders {
  padding: 30px 0px;
}
#detail .modal-content {
  background-color: #7f3883 !important;
}
#detail h5 {
  color: #fff;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}
#detail .close {
  color: #fff;
  opacity: 1;
}
#detail .modal-body p {
  color: #fff;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  margin-top: -20px;
}
p.asnmnt {
  width: 100%;
  float: left;
  font-size: 15px !important;
  font-weight: 600;
  padding-left: 16%;
  margin-top: -16px;
}
p.txtasn {
  font-size: 13px !important;
  text-align: justify;
  padding: 50px 0px 0px 0px;
}
#detail .asnmnt {
  padding-left: 18%;
}
.card-foot .fa-arrow-up {
  margin-right: 5px !important;
}
#detail .modal-header {
  width: 90%;
  margin: 0 auto;
}
#detail .modal-footer {
  width: 90%;
  margin: 0 auto;
}
#detail .modal-body {
  padding: 20px 30px;
}
.mdlfootleft {
  float: left;
  text-align: left;
  width: 66%;
}
.mdfootright {
  float: left;
  width: 40%;
}
.mdlfootleft p {
  margin-bottom: 0px !important;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.mdfootright p {
  margin-bottom: 0px !important;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.sbmitfoot {
  display: block !important;
  margin: 0 auto !important;
  color: #7f3883 !important;
  background-color: #fff !important;
  font-size: 13px !important;
  padding: 6px 22px !important;
  margin-bottom: 30px !important;
  margin-top: 50px !important;
}
.sbmitfoot:hover {
  color: #7f3883 !important;
  background-color: #fff !important;
}
.bgmdl .modal-header {
  background-color: #f9f7fa;
  border-radius: 20px 20px 0px 0px;
}
.bgmdl .modal-content {
  border-radius: 20px;
}
.bgmdl .txtasn {
  padding: 0px 0px 0px 0px;
}
.bgmdl .modal-footer {
  width: 90%;
  margin: 0 auto;
}
.bgmdl .modal-footer .form-group {
  width: 100%;
  margin: 0 auto;
}
.bgmdl .modal-footer .form-group label {
  font-size: 13px !important;
}
.bgmdl .modal-footer .form-group textarea {
  font-size: 13px !important;
}
.prfletable td {
  width: 135px;
}
.sbmitfoot {
  display: block !important;
  margin: 0 auto !important;
  color: #fff !important;
  background-color: #7f3883 !important;
  font-size: 13px !important;
  padding: 6px 22px !important;
  margin-bottom: 30px !important;
  margin-top: 20px !important;
}
.sbmitfoot:hover {
  color: #7f3883 !important;
  background-color: #fff !important;
}
#feedbackmod .modal-body {
  text-align: center;
  margin: 0 auto;
}
.fdmgpp {
  height: 30px;
}
#feedbackmod p {
  font-size: 15px;
}
#feedbackmod .usrimg {
  height: 60px;
  border: 2px solid #ced4da;
}
#feedbackmod .ratingimg {
  height: 14px;
  padding-left: 4px;
  border-radius: 0px !important;
  margin-top: -5px;
}
#feedbackmod .modal-footer .form-group label {
  font-size: 13px !important;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
#filters .modal-title img {
  width: 22px;
  margin-right: 10px;
}
#filters .input-group-addon {
  position: absolute;
  z-index: 11;
  top: 33%;
  left: 10px;
  font-size: 12px;
  color: #bbbbbb;
}
#filters .form-control {
  padding-left: 6%;
  font-size: 12px;
  padding: 15px 20px 15px 6%;
  height: 100%;
  color: #545454;
}
#filters select {
  padding-left: 10px !important;
}

#schdule img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border: 2px solid #b2b2b2;
}
.etcp {
  padding-left: 7%;
  margin-top: -2%;
}
.etcp span {
  float: right;
  font-size: 12px;
}
#schdule .modal-dialog {
  max-width: 800px;
}
#schdule h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #000;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  width: 95%;
  margin: 0 auto;
  margin-top: 25px;
}
#schdule h4 i {
  padding: 0px 30px;
  color: #7e7e7e;
}
#schdule .modal-body {
  padding: 0px !important;
}
.bgimghd {
  background-color: #eeeeee;
  padding: 15px 16px 1px 16px;
}
.calender-boxes {
  display: flex;
}
#schdule .row {
  padding: 15px 20px;
  border-bottom: 1px solid #dddddd;
  margin: 0px 15px;
}
.calbox1 {
  background-color: #ede6ed;
  margin-right: 20px;
  padding: 9px 12px;
  border-radius: 10px;
}
.calbox1 p {
  font-size: 12px;
  margin-bottom: 0px;
}
.left-cal p {
  color: #7f3884;
}
.left-cal b {
  font-size: 25px;
}
.left-cal {
  padding-right: 15px;
  border-right: 2px solid #fff;
}
.right-cal {
  margin-left: 15px !important;
}
.weektxt {
  position: relative;
  top: 35%;
  font-size: 16px;
  color: #585858;
  font-family: "Open Sans", sans-serif;
}
.calbox1.d-flex.active {
  background-color: #7f3883;
}
.calbox1.d-flex.active p {
  color: #fff;
}
.cus-message.d-flex.mb-2.replymsg {
  place-content: flex-end;
  margin-right: 30px;
}

button.btn.btn-theme-light.btn-rounded.schedulebtn {
  padding: 10px 25px;
  margin: 0 auto;
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
}
.togleftin {
  flex: 0 0 100px !important;
  max-width: 100px !important;
}
.togleftin li span {
  display: none;
}
.togleftin .menu-link {
  display: flex;
  margin-bottom: 15px;
}
.togleftin .menu-link img {
  height: 30px !important;
}
.logo-mini {
  display: none;
}
.togleftin .logo-mini {
  display: block !important;
}
.togleftin .logo-big {
  display: none !important;
}
.togrightin {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 100px);
  max-width: calc(100% - 100px) !important;
}
#submitmd .modal-footer span {
  float: left;
  text-align: left;
  width: 100%;
  font-size: 13px;
}
#submitmd .modal-footer a {
  background-color: #ede6ed !important;
  color: #7f3883 !important;
  border-color: #ede6ed !important;
  margin-left: 10px;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 50px;
}
#submitmd .modal-footer i {
  margin-right: 5px !important;
}
.uploadesfiles {
  float: left;
  width: 100%;
}
.uploadesfiles p {
  font-size: 13px;
}
.uploadesfiles i {
  font-size: 13px;
  color: red;
  padding-left: 10px;
}
textarea#exampleFormControlTextarea1 {
  font-size: 13px;
}
.mobile-header {
  display: none;
}
.msgppdiv {
  display: none;
}
.btn.btn-theme-light i {
  margin-right: 0px;
}
.bimger {
  width: 100%;
  min-height: 198px;
  background: transparent linear-gradient(180deg, #7f3883 0%, #401c42 100%) 0%
    0% no-repeat padding-box;
  box-shadow: -2px 3px 6px #7f388380;
  opacity: 1;
}
.bimger .actvbtn {
  position: absolute;
  right: 0;
  bottom: 26%;
  background-color: #fff !important;
  color: #261427 !important;
  font-weight: 600;
}
h2.allessn {
  font-size: 13px;
  font-family: "Open Sans", sans-serif !important;
  color: #fff;
  margin-top: 20px;
}
.allessn i {
  background-color: #dfcde0;
  padding: 2px;
  color: #7f3883;
  margin-right: 5px;
  font-size: 16px;
  border-radius: 2px;
}
.bimger img {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
}
.bimger .container-fluid {
  width: 85%;
}
.header-bg .container-fluid {
  width: 85%;
}
.lsns h1 {
  font-size: 25px;
  color: #fff;
}
.lsns .allessn {
  margin-left: 0px;
  font-weight: 600;
  font-size: 15px;
}
.header-bg {
  margin-top: 3%;
}
.bimger .row.icns {
  border: 0px !important;
  margin: 0px !important;
}
.lsns {
  margin-top: 3%;
}
.lsns i {
  background-color: transparent;
  color: #fff;
}
span.bgylw {
  background-color: #e5a300;
  padding: 3px 5px;
  border-radius: 50px;
  display: inline-block;
}
div#completeorders {
  margin-top: 2%;
}
.bimger .actvbtn {
  position: absolute;
  right: 0;
  bottom: 6%;
  background-color: #fff !important;
  color: #261427 !important;
  font-weight: 600;
}
.bimger .actvbtn img {
  width: 30px;
  margin-top: 0px;
}
.schdlsn p {
  font-size: 13px;
  color: #000;
  line-height: 24px;
}
.schdlsn h2 {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 10px;
}
.row.schdlsn {
  padding-top: 20px;
}
#accordion .card-header {
  background-color: #fff;
  border-bottom: 0px !important;
  border-radius: 20px !important;
  padding: 5px 1.25rem 5px 7px;
}
#accordion .card {
  border-radius: 20px !important;
  margin-bottom: 10px;
}
#accordion .card-header .btn {
  color: #000;
  font-weight: 700;
}
#accordion .card-body {
  padding: 0px 1.25rem 1.25rem 1.25rem;
}

#accordion .card-header .btn {
  padding: 5px 1.25rem 5px 7px;
}
#accordion .card-header h5:before {
  content: "\f078";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 600;
  padding-left: 10px;
  font-size: 14px;
}
#accordion .card-header.collapsed h5:before {
  content: "\f077";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 600;
  padding-left: 10px;
  font-size: 14px;
}
.dropdown-item img {
  width: 20px;
}
.dropdown-item p {
  font-size: 14px;
}
.dropdown-item {
  padding: 10px 20px 10px 10px !important;
  margin-right: 15px !important;
  border-bottom: 1px solid #ccc;
}
.card-body.p-1.tblbdycrd {
  padding: 0px !important;
}
.card-foot button {
  margin-right: 8px;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.lesnhomepge .card-foot button {
  margin-right: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.nav-tabs .nav-link {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.modal-dialog {
  max-width: 400px;
}
.paginav {
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 1111;
}
ul.pagination {
  float: right;
  border-radius: 50px !important;
  background-color: #fff;
  padding: 0px;
  margin-right: 3%;
}
.close img {
  width: 20%;
  float: right;
}
#detail .close img {
  -webkit-filter: brightness(0) invert(1) !important;
          filter: brightness(0) invert(1) !important;
}
button:focus {
  outline: 0px !important;
}
.card-foot button {
  margin-bottom: 20px;
}
.pagination .page-link {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #000;
}
.page-link {
  padding: 0px !important;
}
li.page-item {
  padding: 8px 10px;
}
a.page-link.actvepgn {
  background-color: #7f3883;
  color: #fff;
  border-radius: 50px;
  padding: 1px 5px !important;
}
.modal-footer .btn i {
  margin-right: 5px;
}
.btn .fa-calendar-alt {
  margin-right: 5px;
}
.btn .fa.fa-eye {
  margin-right: 5px;
}
.cus-table i {
  margin-right: 0px !important;
}
.content-slider .course-name h4 img {
  margin-top: -10px;
}
.close {
  font-size: 16px;
  font-weight: 400;
}
.close i {
  margin-top: 3px;
}
#recent .mbmsgpp .cus-message {
  padding: 10px 20px;
}
.hdmbf .userimg {
  flex: 0 0 60px !important;
  max-width: 60px !important;
}
.hdmbf .userimg img {
  height: 60px !important;
  width: 60px !important;
}
.edit-profile .card-header h4 {
  margin: inherit !important;
}
.side-buttons a {
  padding: 6px 30px !important;
  margin-right: 10px;
}
.lsnspan {
  background-color: #fff;
  border-radius: 50px;
  color: #642c68;
  padding: 3px 8px;
  margin-right: 10px;
}
.progress {
  border-radius: 50px;
  margin-top: 5px;
}
.progress-bar {
  background: #2cdb10;
}
.progress-bar.bg-success {
  background-color: #e5e904 !important;
}

.mdicns i {
  display: -webkit-inline-box !important;
  font-size: 14px !important;
  margin-right: 10px !important;
}
.mdicns p {
  margin-top: 0px !important;
}
.helpsupport h2 {
  margin: 20px 30px;
}
.helpsupport .bimger {
  height: auto !important;
  padding: 40px 0px;
}
.helpsupport .bimger img {
  border: 0px !important;
}
.helpsupport p {
  color: #fff;
  text-align: right;
  font-size: 18px;
}
.phonebox {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.phonebox p {
  text-align: left !important;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
}
.phonebox span {
  font-weight: 400;
}
.phonebox i {
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
  color: #7f3883;
  margin-right: 20px;
  font-size: 24px;
  vertical-align: text-top;
}
.hdcol .hdtxt b {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.col-md-3.hdcol {
  border: 1px solid #7f3884;
  flex: 0 0 23%;
  max-width: 23%;
  margin-right: 2%;
  padding: 15px;
  border-radius: 10px;
}
.hdcol .hdtxt {
  font-size: 15px;
  margin-bottom: 0px;
}
.col-md-3.hdcol.active {
  border: 3px solid #7f3884;
}

.hdtxt {
  font-size: 15px;
  margin-bottom: 0px;
}
.calbox1.d-flex.align-items-center.actvee {
  background-color: #7f3884;
  color: #fff;
}
.actvee p {
  color: #fff !important;
  padding: 0px 10px;
}
.actvee i {
  margin-right: 10px;
}
#schdule span {
  color: #585858;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  opacity: 70%;
}
#schdule .close img {
  height: 25px;
  width: 25px;
  border: none !important;
}
/*h5#exampleModalLabel {
    font-weight: 700;
    color: #5C5C5C;
}*/
.modal-header .close {
  padding: 0.5rem 1rem;
}
button.btn.btn-theme-light.btn-rounded.schedulebtn {
  background-color: #7f3884 !important;
  color: #fff !important;
}
.cnlnbtn .wticnmb {
  position: absolute;
  bottom: 10px;
  right: 0;
}
.usrmsg {
  width: 100%;
}
.lesnhomepge .lesson-inner {
  min-height: 105px;
}
.lesnhomepge .row .icns {
  min-height: 110px;
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .card-foot button {
    margin-right: 10px;
    padding: 5px 15px !important;
  }
  .col-xl-3 {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
  p.activedate {
    padding: 0px;
  }
  .card-foot button {
    margin-bottom: 20px;
  }
  .rate-teacherbox .subject p {
    font-weight: 500;
    font-size: 12px;
  }
  .rate-teacherbox button.btn {
    border-radius: 30px !important;
    font-size: 12px;
  }
  p.ratings {
    left: 39%;
  }
  .order-right h3 {
    font-size: 24px;
  }
  .order-left img {
    height: 216px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-lg-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  p.ratings {
    position: absolute;
    left: 40%;
  }
  .card-foot button {
    margin-bottom: 20px;
  }
  .rate-teacherbox .subject p {
    font-weight: 500;
    font-size: 12px;
  }
  .rate-teacherbox button.btn {
    border-radius: 30px !important;
    font-size: 12px;
  }
  .lcnschdl .col-md-2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .cnlnbtn {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .bimger {
    height: 225px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .dashboard .left-sidebar {
    flex: 0 0 220px;
    max-width: 220px;
  }
  .dashboard .right-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 220px);
    max-width: calc(100% - 220px);
  }
  .dashboard .left-sidebar .left-sidebar-inner .logo {
    padding: 15px 10px;
  }
  .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
    padding: 15px 10px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link {
    padding: 9px 12px;
    font-size: 13px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link
    img {
    height: 14px;
    max-width: 16px;
    margin-right: 9px;
  }
  .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
    margin-bottom: 8px;
  }
  .card-foot .btn {
    margin-bottom: 20px;
  }

  .order-right {
    width: 75%;
  }
  .order-left {
    width: 25%;
  }
  .oderactve i {
    display: none;
  }
  .card-foot button {
    margin-bottom: 20px;
  }
  .lesnhomepge .card-foot button {
    margin-right: 5px;
  }
  .lcnschdl .col-md-2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .cnlnbtn {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .bimger {
    height: 240px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard .left-sidebar {
    flex: 0 0 100px !important;
    max-width: 100px !important;
  }
  .dashboard .right-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
  }
  .left-sidebar.show-sidebar.togleftin {
    flex: 0 0 300px !important;
    max-width: 300px !important;
  }
  .right-sidebar.d-flex.flex-column.togrightin {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
  }
  .togleftin li span {
    display: block !important;
    padding-left: 10px;
  }
  .cuslessons .card .card-foot {
    padding: 15px 6px;
  }
  .menu-link span {
    display: none;
  }
  .logo-big {
    display: none !important;
  }
  .logo-mini {
    display: block !important;
  }
  .dashboard .left-sidebar .left-sidebar-inner .logo {
    padding: 15px 10px;
  }
  .dashboard .left-sidebar .left-sidebar-inner .siderbar-menu {
    padding: 15px 10px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link {
    padding: 9px 12px;
    font-size: 13px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link
    img {
    height: 30px;
    max-width: 20px;
    margin-right: 9px;
    margin: 0 auto;
  }
  /*.dashboard .left-sidebar .left-sidebar-inner .siderbar-menu li.menu-item {
        margin-bottom: 0px !important;
    }*/
  a.logo-mini.d-md-none img {
    display: block;
  }
  .card-theme {
    margin-bottom: 1em;
  }
  .userprofile a .user-name {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  .top-rightbar .saerchbar {
    flex: 0 0 100px;
    max-width: 100px;
    text-align: right;
  }
  header {
    position: relative;
  }
  .top-rightbar .saerchbar .input-group {
    position: absolute;
    width: 90%;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    z-index: 11;
    opacity: 1;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
  }
  .top-rightbar .saerchbar .input-group .input-group-append {
    top: 21px;
    right: 21px;
  }
  .top-rightbar .show-searchbox .input-group {
    visibility: visible;
    opacity: 1;
  }
  .top-rightbar .saerchbar .mobile-search button#search {
    border-radius: 50px;
    background-color: #ede6ed;
    height: 42px;
    width: 42px;
    color: #7f3883;
    border-color: #ede6ed;
    box-shadow: none;
    outline: none;
  }
  .dash-content {
    padding: 20px 15px;
  }
  .filters {
    top: 0px !important;
  }
  p.ratings {
    left: 40%;
    top: 14%;
  }
  .order-right {
    margin-bottom: 30px;
  }
  .card-foot button {
    margin-bottom: 20px;
  }
  .col-md-4.col-5.filtrcol {
    position: relative;
  }
  #recent .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
    display: none;
  }
  #recent .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .msgppdiv .btm_message_text {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 5px 20px 5px;
    position: relative;
    width: 95.5%;
    margin-left: 15px;
  }
  .cus-message.d-flex.mdcstmmsg {
    padding-left: 30px;
    padding-top: 18px;
  }
  a.hidedv {
    font-size: 22px !important;
    padding-top: 10px;
    padding-right: 20px;
  }

  .lcnschdl .col-md-2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .cnlnbtn {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .message-detail {
    width: 100%;
  }
}
@media screen and (min-width: 540px) and (max-width: 720px) {
  p.ratings {
    left: 43%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .cuslessons .card .card-foot {
    padding: 15px 8px;
  }
  .cuslessons .card .card-foot button.btn {
    font-size: 12px;
    padding: 5px 13px;
  }
  .cuslessons .card .card-body .lesson-inner {
    font-size: 12px;
  }
  .cuslessons .card .card-body .lesson-inner .lesson-heading {
    width: 100%;
  }
  .cuslessons .card .card-header .course p {
    font-size: 11px;
  }
  .cus-table table tbody tr td p.btn {
    font-size: 13px;
    white-space: nowrap;
  }
  .cus-table table tbody tr td .course {
    width: 148px;
  }
  .cus-table table tbody tr td:last-child {
    white-space: nowrap;
  }
  .rate-teacherbox .subject p {
    font-size: 12px;
  }
  .rate-teacherbox button.btn {
    font-size: 13px;
  }
  #recent .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
    display: none;
  }
  #recent .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .msgppdiv .btm_message_text {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 5px 20px 5px;
    position: relative;
    width: 95.5%;
    margin-left: 15px;
  }
  .cus-message.d-flex.mdcstmmsg {
    padding-left: 30px;
  }
  a.hidedv {
    font-size: 22px !important;
    padding-top: 10px;
    padding-right: 20px;
  }
  .bimger {
    height: 250px !important;
  }
  .lcnschdl .col-md-2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-9 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-6 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .cnlnbtn {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .order-right h3 {
    font-size: 21px;
  }
  .order-left img {
    height: 209px;
  }
  .mytasks .prgrs .clck {
    left: 16%;
  }
  p.ratings {
    left: 41%;
  }
}
@media screen and (max-width: 767px) {
  .dashboard .right-sidebar {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: 0 !important;
  }
  .dashboard .left-sidebar.show-sidebar {
    right: 0;
  }
  .close-btn i::before {
    content: "\f00d";
  }
  .userprofile a .user-name {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  .top-rightbar .saerchbar {
    flex: 0 0 300px;
    max-width: 300px;
    text-align: right;
  }
  .card-theme {
    margin-bottom: 0.61em !important;
    height: auto !important;
  }
  .dashboard .left-sidebar {
    flex: 0 0 260px;
    max-width: 260px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link {
    padding: 8px 12px !important;
    font-size: 13px !important;
    margin-bottom: 8px;
  }
  .dashboard
    .left-sidebar
    .left-sidebar-inner
    .siderbar-menu
    li.menu-item
    .menu-link
    img {
    height: 14px;
    max-width: 16px;
  }
  .nav-tabs {
    border-bottom: 1px solid #d9c3da;
    display: -webkit-inline-box;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: inherit;
  }
  #recent .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
    display: none;
  }
  #recent .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .msgppdiv .btm_message_text {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 5px 20px 5px;
    position: relative;
    width: 95.5%;
    margin-left: 15px;
  }
  .cus-message.d-flex.mdcstmmsg {
    padding-left: 30px;
  }
  a.hidedv {
    font-size: 22px !important;
    padding-top: 10px;
    padding-right: 20px;
  }
  .lsnmddvce .col-md-2 {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .bimger {
    height: auto !important;
  }
  .lcnschdl .col-md-2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-3 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .lcnschdl .col-md-9 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .cnlnbtn {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .dsktphead {
    display: none !important;
  }
  div#filters {
    width: 360px;
  }
  .mobile-header {
    display: block !important;
  }
  .mobile-header header {
    background-color: #261427 !important;
    padding: 20px 10px !important;
    position: relative;
  }
  .top-rightbar .saerchbar {
    flex: 0 0 100px;
    max-width: 100px;
  }
  .wticnmb i {
    display: none;
  }
  .mobile-header .toggle-icon a.btn {
    font-size: 16px;
    height: 30px;
    width: 30px;
    right: 10px;
  }
  .mobile-header .top-rightbar .notification button.btn {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .mobile-header .top-rightbar .notification button.btn span.badge {
    top: -12px;
  }
  .mobile-header .userprofile a .userimg img {
    height: auto;
    width: auto;
  }
  .mobile-header img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .mobile-header .input-group {
    background-color: #261427;
    padding: 20px 15px;
  }
  .mobile-header .btn-outline-secondary {
    background-color: #ede6ed;
    color: #7f3883;
    border-radius: 50px !important;
    position: absolute;
    right: 3%;
    width: 40px;
  }
  .mobile-header .form-control {
    width: 100%;
    padding: 17px 20px !important;
    border-radius: 50px !important;
  }
  .lgimg {
    position: absolute;
    left: 0;
    width: 45% !important;
    left: 19%;
    top: 25%;
  }
  .filters {
    top: -1.7% !important;
  }
  #detail .modal-body p {
    color: #fff;
    font-size: 20px;
  }
  ul.nav.nav-tabs {
    margin-top: 20px;
  }
  p.asnmnt {
    padding-left: 16%;
  }
  .tab-content .card-body {
    padding: 20px 0px;
  }
  .calender-boxes {
    display: flex;
    overflow-x: scroll;
  }
  .etcp {
    padding-left: 14%;
  }
  #submitmd .modal-footer a {
    display: inline-block;
    margin-top: 10px;
  }
  .order-left {
    width: 25%;
  }
  .order-right {
    width: 75%;
  }
  .order-left img {
    height: 193px;
  }
  .cuslessons .card .card-foot button.btn {
    font-size: 12px;
  }
  .order-right .btn i {
    display: none;
  }
  .filters {
    position: relative;
    float: right;
  }
  #scheduledorders h3 {
    color: #000;
    font-size: 18px;
  }
  .cus-heading {
    font-size: 20px;
    text-align: left;
  }
  .profile-information {
    margin-bottom: 20px;
  }
  .card.card-theme.mb-4.prfle {
    margin-bottom: 20px !important;
  }
  .wticnmb img {
    display: none;
  }
  .wticnmb {
    font-size: 12px;
  }
  .sliders .carousel-item .row {
    text-align: center;
  }
  .sliders .carousel-item .row .course-name {
    justify-content: center;
  }
  .cuslessons .card .card-body .lesson-inner .lesson-heading {
    width: 100%;
  }
  .cuslessons .card .card-body .lesson-inner {
    font-size: 15px;
  }
  .cuslessons .card .card-body {
    padding: 15px 20px;
  }
  .cuslessons .card .card-foot button.btn {
    width: auto;
    display: inline-block;
  }
  .cus-table table tbody tr td p.btn {
    white-space: nowrap;
  }
  .cus-table table tbody tr td:last-child {
    white-space: nowrap;
  }
  .cus-table table tbody tr td .course {
    white-space: normal;
    width: 140px;
  }
  .dashboard .right-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 00px);
    max-width: calc(100% - 0px) !important;
  }
  .dashboard .left-sidebar {
    flex: 0 0 !important;
    max-width: 0px !important;
  }
  .left-sidebar.show-sidebar.togleftin {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    left: 0;
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1111111;
  }
  .togleftin li span {
    display: block !important;
    font-size: 16px;
    padding-left: 10px;
  }

  .moblst ul {
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    display: -webkit-inline-box;
    margin-left: 15px;
    overflow-x: scroll;
  }
  .menuimg {
    padding: 10px 15px;
    border: 1px solid #431d46;
    background-color: #431d46;
    border-radius: 50px;
    margin-right: 10px;
  }
  .moblst ul li {
    width: auto;
    padding: 10px 5px;
  }
  .moblst li img {
    height: 30px;
    object-fit: contain;
  }
  .moblst .menu-link span {
    display: block !important;
    color: #fff;
    padding-top: 10px;
    text-align: center;
  }
  .moblst {
    background-color: #39183b;
    padding: 10px 0px;
  }
  #recent .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  #recent .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
    display: none;
  }
  .btm_message_text {
    position: relative;
  }
  .msgppdiv {
    background-color: #e4e4e4;
    border-radius: 20px;
    margin: 0 20px;
  }
  .msgppdiv .name {
    font-size: 16px;
    font-weight: 600;
  }
  .msgppdiv .message p {
    font-size: 10px;
  }
  .msgppdiv .mdcstmmsg {
    padding: 20px 20px 5px 20px;
  }

  .msgppdiv .btm_message_text {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 5px 20px 5px;
  }
  .msgppdiv .cus-message.d-flex.mb-2.replymsg {
    float: right;
    margin-right: 0px;
  }
  .msgppdiv .form-group textarea {
    margin: 0 auto;
    display: block;
    width: 100%;
    border-radius: 50px !important;
    margin-left: 5%;
    margin-top: 5% !important;
    padding: 20px 0px 20px 20px;
  }
  .message-detail.lstdv {
    margin-bottom: 30px;
  }
  .hidedv {
    font-size: 30px;
    margin-right: 20px;
    color: #000;
    font-weight: 500;
  }
  .btn.btn-theme-light i {
    margin-right: 0px;
  }
  .bimger {
    height: 100% !important;
  }
  .bimger .actvbtn {
    position: relative;
    width: auto;
    display: block;
    margin: 0 auto;
    margin-top: 40px;
  }
  .bimger .actvbtn img {
    display: none;
  }
  .bimger img {
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    margin: 0 auto;
    /*display: block;*/
  }
  h2.allessn {
    /*text-align: center;*/
    margin-bottom: 20px;
  }
  .close-btn {
    position: absolute;
    z-index: 11111111;
    right: 0%;
    top: 20px;
  }
  .dashboard {
    position: relative;
  }
  .order-right h3 {
    font-size: 20px;
  }
  .order-right .price {
    font-size: 20px !important;
  }
  .lsns h1 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1550px) {
  .dashboard .left-sidebar {
    flex: 0 0 100px!important;
    max-width: 100px!important;
  }
  .dashboard .right-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px) !important;
  }
  .left-sidebar.show-sidebar.togleftin{
    flex: 0 0 300px !important;
    max-width: 300px !important;
  }
  .right-sidebar.d-flex.flex-column.togrightin{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 300px);
    max-width: calc(100% - 300px) !important;
  }
  .togleftin li span {
    display: block !important;
    padding-left: 10px;
  }
  .togleftin .menu-link {
    display: flex;
    margin-bottom: 0px;
  }
  .menu-link span{
    display:none;
  }
  .logo-big {
    display:none !important;
  }
  .logo-mini{
    display:block !important;
  }
  .togleftin .logo-big {
    display: block !important;
  }
  .togleftin .logo-mini {
    display:none !important;
  }
}
