.modal-header{
    background-color: #f9f7fa;
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
}
.modal-content{
     border-radius: 20px;
     opacity: 1,
}
.txtasn{
    padding: 0px 0px 0px 0px;
}
.modal-footer{
    width:90%;
    margin:0 auto;
}
.modal-footer .form-group{
    width:100%;
    margin:0 auto;
}
.modal-footer .form-group label {
   font-size: 13px !Important;
}
.modal-footer .form-group textarea {
   font-size: 13px !Important;
}
body.open-modal{
    overflow: hidden;
}