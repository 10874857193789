.donutchart-track{
  fill: transparent;
  stroke: #ddd;
  stroke-width: 26;
}

.donutchart-track-inner{
  fill: #ddd;
  stroke-width: 26;
}
.donutchart-indicator {
  fill: transparent;
  stroke: #7f3883;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray .3s ease;
}

.donutchart {
  margin: 0 auto;
  border-radius: 45%;
  display: block;
}


.donutchart-text{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  fill: #7f3883;
  font-weight: 600;
  padding-top: 20px;
}
.donutchart-text-val{
  font-size:14px;
}
.donutchart-text-percent{
  font-size:14px;
}
.donutchart-text-label{
  font-size:9px;
}

